import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { integrationsApi, useAuthContext, useInitTodoIntegration } from '../../../data-access';
import './TickTickAuthCallback.scss';
import { Path } from '../../../routing/path';
import { trackEventMixpanel } from '../../../utils';

interface ExchangeCodeRes {
  accessToken: string;
}

const TickTickAuthCallback: React.FC = () => {
  const { initTodoIntegration } = useInitTodoIntegration();
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    const exchange = async () => {
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      const params = state ? new URLSearchParams(new URL(state).search) : undefined;
      const redirectTo = params?.get('redirectTo') ?? Path.DASHBOARD;

      if (!code) {
        return;
      }
      const { data } = await integrationsApi.exchangeCode(
        code,
        'ticktick',
        window.location.origin + '/callback/ticktick?redirectTo=' + redirectTo
      );

      if (!data) {
        return;
      }
      const accessToken = (data as ExchangeCodeRes).accessToken;

      const integrationId = await initTodoIntegration({
        token: accessToken,
        todoType: 'ticktick',
        userId: user.userId,
      });

      trackEventMixpanel('connect_todo_auth_done', { todoType: 'ticktick' });

      navigate(redirectTo || Path.DASHBOARD);
    };

    exchange();
  }, [user.userId]);

  return (
    <div className="ticktick-auth-callback">
      <CircularProgress />
    </div>
  );
};

export default TickTickAuthCallback;

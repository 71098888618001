import { FC } from 'react';

import './DashboardPage.scss';

import { SideBar } from './SideBar';
import KanbanView from './KanbanView/KanbanView';
import { CalendarView } from './CalendarView';
import {
  CoreActions,
  SettingsModal,
  WearableErrorSnackBar,
  TaskErrorSnackBar,
  CalendarEventSnackBar,
  ViewSwitcher,
  DataView,
} from '../../components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { settingsModalAtom, viewSwitcherAtom } from '../../data-access';
import { useFeatureFlag } from '../../utils';

const DashboardPage: FC = () => {
  const [settingsModalVisible, setSettingsModalVisible] = useRecoilState(settingsModalAtom);
  const currentView = useRecoilValue(viewSwitcherAtom);

  const {
    featureFlags: { FF_data_screen_web = false },
  } = useFeatureFlag();

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard-page__sidebar-container">
          <SideBar className="dashboard-page__side-bar" />
        </div>
        <div className="dashboard-page__content">
          <div className="dashboard-page__core-actions">
            {FF_data_screen_web && <ViewSwitcher />}
            <CoreActions />
          </div>
          {currentView === 'Home' ? (
            <div className="dashboard-page__kanban-calendar-container">
              <KanbanView className="dashboard-page__kanban-calendar-container__kanban-view" />
              <CalendarView className="dashboard-page__kanban-calendar-container__calendar-view" />
            </div>
          ) : (
            <div className="dashboard-page__data-view-container">
              <DataView />
            </div>
          )}
        </div>
      </div>
      <SettingsModal
        visible={settingsModalVisible.visible}
        onClose={() => setSettingsModalVisible({ visible: false, defaultPosition: undefined })}
        defaultPosition={settingsModalVisible.defaultPosition}
      />
      <WearableErrorSnackBar />
      <TaskErrorSnackBar />
      <CalendarEventSnackBar />
    </>
  );
};

export default DashboardPage;

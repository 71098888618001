import React from 'react';

import './TodoListItem.scss';
import { TodoIntegrationType } from '@demind-inc/core';
import { TodoListMoreDropdown, TodoListMoreDropdownOption } from '../TodoListMoreDropdown';
import { useDropdownState } from '../../../../hooks';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

interface TodoListItemProps {
  iconSource: string;
  todoType: TodoIntegrationType;
  isDisconnecting?: boolean;
  isSyncing?: boolean;
  onDeleteIntegration: () => void;
  onSyncIntegration: () => void;
}

const TodoListItem: React.FC<TodoListItemProps> = ({
  todoType,
  iconSource,
  isDisconnecting = false,
  isSyncing = false,
  onDeleteIntegration,
  onSyncIntegration,
}) => {
  const { anchorEl, handleCloseDropdown, handleOpenDropdown } = useDropdownState();

  const sourceTitle = todoType.charAt(0)?.toUpperCase() + todoType.slice(1);

  const handleMoreDropdownItemClick = async (option: TodoListMoreDropdownOption) => {
    if (option === 'disconnect') {
      onDeleteIntegration();
    } else if (option === 'sync') {
      onSyncIntegration();
    }
    handleCloseDropdown();
  };

  const renderLoadingOption = () => {
    if (isDisconnecting) {
      return 'disconnect';
    } else if (isSyncing) {
      return 'sync';
    }
    return undefined;
  };

  return (
    <>
      <div className="todo-list-item">
        <img src={iconSource} alt={todoType} className="todo-list-item__icon" />
        <div className="todo-list-item__text">{sourceTitle}</div>
        <IconButton
          aria-label="todo-more"
          size="small"
          color="inherit"
          className="todo-list-item__more"
          onClick={handleOpenDropdown}
        >
          <MoreVert />
        </IconButton>
      </div>
      <TodoListMoreDropdown
        anchorEl={anchorEl}
        sourceTitle={sourceTitle}
        onClose={handleCloseDropdown}
        loadingOption={renderLoadingOption()}
        onClickItem={handleMoreDropdownItemClick}
      />
    </>
  );
};

export default TodoListItem;

import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { SleepDetailsByDate, SleepStage } from '@demind-inc/core';

import {
  dataTrendPeriodAtom,
  getWeekDatesOf,
  selectedDateAtom,
  useAuthContext,
  useMetricsContext,
} from '../data-access';
import { sum } from 'lodash';
import { translateSecondsToHourAndMinutes } from '../helpers';
import { useSleepWeeklyTrend } from '../data-access/queries/useSleepWeeklyTrend';

export const useSleepTrend = () => {
  const dataTrendPeriod = useRecoilValue(dataTrendPeriodAtom);
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { user } = useAuthContext();

  const weekEdge = useMemo(() => {
    const week = getWeekDatesOf(selectedDate);
    return {
      startDate: week[0],
      endDate: week[week.length - 1],
    };
  }, [selectedDate]);

  const { sleepTrend: weeklySleepTrend, isLoading: isWeeklyTrendLoading } = useSleepWeeklyTrend({
    userId: user.userId,
    metricId: user.metricId!,
    startDate: weekEdge.startDate.format('YYYY-MM-DD'),
    endDate: weekEdge.endDate.format('YYYY-MM-DD'),
    enabled: dataTrendPeriod === 'weekly',
  });

  const { getSleepOnDate, isLoading: isFetchingSleep } = useMetricsContext();
  const {
    sleepStage: dailySleepStage,
    totalSleepHours: dailyTotalSleepHours,
    awakeTime: dailyAwakeTime,
    sleepTime: dailySleepTime,
  } = useMemo(() => getSleepOnDate(selectedDate), [getSleepOnDate, selectedDate]);

  const isLoading = dataTrendPeriod === 'daily' ? isFetchingSleep : isWeeklyTrendLoading;

  const targetSleepHours = useMemo(() => {
    if (dataTrendPeriod === 'daily') {
      return dailyTotalSleepHours;
    } else {
      return translateSecondsToHourAndMinutes((weeklySleepTrend?.avgDurationMin ?? 0) * 60);
    }
  }, [dataTrendPeriod, weeklySleepTrend, dailyTotalSleepHours]);

  const hasNoSleepData = useMemo(
    () => targetSleepHours.hours === 0 && targetSleepHours.minutes === 0,
    [targetSleepHours]
  );

  const { targetSleepStage, targetAwakeTime, targetSleepTime } = useMemo(() => {
    let targetSleepStage: SleepStage | undefined = undefined;
    let targetAwakeTime: string | undefined = undefined;
    let targetSleepTime: string | undefined = undefined;

    if (dataTrendPeriod === 'daily') {
      targetSleepStage = dailySleepStage;
      targetAwakeTime = dailyAwakeTime;
      targetSleepTime = dailySleepTime;
    } else {
      targetSleepStage = weeklySleepTrend?.avgStage as SleepStage | undefined;
      targetAwakeTime = undefined;
      targetSleepTime = undefined;
    }

    return { targetSleepStage, targetAwakeTime, targetSleepTime };
  }, [dataTrendPeriod, weeklySleepTrend, dailySleepStage, dailyAwakeTime, dailySleepTime]);

  const hasNoSleepStage = useMemo(
    () => sum(Object.values(targetSleepStage ?? {})) === 0,
    [targetSleepStage]
  );

  return {
    dataTrendPeriod,
    dataByDate: (weeklySleepTrend?.data ?? []) as SleepDetailsByDate[],
    isLoading,
    hasNoSleepData,
    targetSleepHours,
    targetSleepStage,
    targetAwakeTime,
    targetSleepTime,
    hasNoSleepStage,
  };
};

import { useQuery } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseSleepTrendParams {
  userId: string;
  metricId: string;
  startDate: string;
  endDate: string;
  enabled?: boolean;
}

interface UseSleepWeeklyTrendQueryParams {
  queryKey: ['lifestack.trend.sleep.weekly', UseSleepTrendParams];
}

export const useSleepWeeklyTrend = ({
  userId,
  metricId,
  startDate,
  endDate,
  enabled = true,
}: UseSleepTrendParams) => {
  const sleepTrendQuery = useQuery({
    queryKey: ['lifestack.trend.sleep.weekly', { userId, metricId, startDate, endDate }],
    queryFn: ({ queryKey }: UseSleepWeeklyTrendQueryParams) => {
      const [, { userId, metricId, startDate, endDate }] = queryKey;
      return metricsApi
        .getWeeklySleepTrend(metricId, userId, startDate, endDate)
        .then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!userId && !!metricId && enabled,
  });

  return {
    sleepTrend: sleepTrendQuery.data,
    ...sleepTrendQuery,
  };
};

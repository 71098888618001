import clsx from 'clsx';
import React, { useState } from 'react';
import { TodoIntegration, TodoIntegrationType } from '@demind-inc/core';
import AddIcon from '@mui/icons-material/Add';

import { ICONS } from '../constants';
import './TodoList.scss';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { useDropdownState, useStartTodoAuth } from '../../../hooks';
import { TodoListItem } from './TodoListItem';
import {
  useAuthContext,
  useRemoveTodoIntegration,
  useSyncTodoProjects,
  useTodoTasksContext,
} from '../../../data-access';

interface TodoListProps {
  className?: string;
}

const TodoList: React.FC<TodoListProps> = ({ className }) => {
  const [disconnectingTodoType, setDisconnectingTodoType] = useState<TodoIntegrationType | null>(
    null
  );
  const [syncingTodoType, setSyncingTargetType] = useState<TodoIntegrationType | null>(null);

  const { anchorEl, handleCloseDropdown, handleOpenDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { connectedTodos, handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const { removeTodoIntegration } = useRemoveTodoIntegration();
  const { syncTodoProjects } = useSyncTodoProjects();
  const { reSyncTasks } = useTodoTasksContext();

  const hanelDeleteIntegration = async (targetTodo: TodoIntegration) => {
    setDisconnectingTodoType(targetTodo.source);
    await removeTodoIntegration({
      userId: user.userId,
      todoIntegrationId: targetTodo.todoIntegrationId,
    });
    setDisconnectingTodoType(null);
  };

  const hanelSyncIntegration = async (targetTodo: TodoIntegration) => {
    setSyncingTargetType(targetTodo.source);
    const newProjectIds = await syncTodoProjects({
      userId: user.userId,
      todoType: targetTodo.source,
    });
    reSyncTasks(newProjectIds, true);
    setSyncingTargetType(null);
  };

  return (
    <div className={clsx('todos', className)}>
      {connectedTodos.map((targetTodo) => (
        <TodoListItem
          key={targetTodo.source}
          iconSource={ICONS[targetTodo.source]}
          todoType={targetTodo.source}
          isDisconnecting={disconnectingTodoType === targetTodo.source}
          isSyncing={syncingTodoType === targetTodo.source}
          onSyncIntegration={() => hanelSyncIntegration(targetTodo)}
          onDeleteIntegration={() => hanelDeleteIntegration(targetTodo)}
        />
      ))}
      <button aria-label="Add todos" onClick={handleOpenDropdown} className="todos__button">
        <div className="todos__button__icon">
          <AddIcon />
        </div>
        <div className="todos__button__text">TODOs</div>
      </button>
      <ConnectTodosDropdown
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={handleOpenTodoAuth}
        generatingTodoAuth={generatingTodoAuth}
        connectedTodos={connectedTodos}
      />
    </div>
  );
};

export default TodoList;

import { FC } from 'react';

import './AuthPage.scss';
import { OnboardingContainer, PrivacyPolicy } from '../../components';
import { useAuthContext } from '../../data-access';

import LifestackLogoName from '../../assets/logo/logo_with_name.png';
import GoogleIcon from '../../assets/onboarding/google.png';
import SignInAsset from '../../assets/onboarding/signin.png';
import SignInWaveAsset from '../../assets/onboarding/signin-wave.png';
import { Button, Typography } from '@mui/material';

const AuthPage: FC = () => {
  const { signIn } = useAuthContext();

  return (
    <div className="auth-page">
      <OnboardingContainer
        leftChildren={
          <div className="auth-page__left-section">
            <img
              src={SignInAsset}
              alt="lifestck signin"
              className="auth-page__left-section__asset"
            />

            <img
              src={SignInWaveAsset}
              alt="lifestck signin"
              className="auth-page__left-section__wave"
            />
          </div>
        }
        rightChildren={
          <div className="auth-page__right-section-wrapper">
            <div className="auth-page__right-section">
              <div className="auth-page__content">
                <Typography variant="h1" className="auth-page__content__title">
                  Reach Your Peaks with Lifestack
                </Typography>
                <Typography variant="h3" className="auth-page__content__description">
                  Lifestack helps you schedule your day with your energy levels in mind by
                  connecting your health data from wearables and smartphones.
                </Typography>
                <Button
                  variant="contained"
                  className="auth-page__content__btn"
                  onClick={() => signIn()}
                >
                  <img src={GoogleIcon} height={24} alt="Google" />
                  Continue with Google
                </Button>
              </div>
            </div>
            <div className="auth-page__privacy-policy">
              <PrivacyPolicy />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AuthPage;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';

interface UseDeleteUserParams {
  userId: string;
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation({
    mutationFn: ({ userId }: UseDeleteUserParams) => {
      return accountApi.deleteUser(userId).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    deleteUser: deleteUserMutation.mutateAsync,
    ...deleteUserMutation,
  };
};

import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { useDeleteCalendarEvent, useUpdateCalendarEvent } from '../mutations';
import { useAuthContext } from './AuthProvider';
import { eventsSnackBarAtom } from '../recoil';
import { RBCEvent } from '../../components';
import { CalendarEventModifyOption, EventDetailsMenuMode } from '../types';
import { useCalendarContext } from './CalendarProvider';
import { useEnergyBoost } from '../../hooks';

interface IEventDetailsMenuContext {
  selectedEvent?: RBCEvent;
  visibleMenuMode?: EventDetailsMenuMode;
  handleSaveEvent: (newEvent: CalendarEventModifyOption) => void;
  handleDeleteEvent: (event: RBCEvent) => void;
  handleSelectEvent: (event: RBCEvent) => void;
  handleStartEditingEvent: () => void;
  clearSelectedEvent: () => void;
}

export const EventDetailsMenuContext = createContext({} as IEventDetailsMenuContext);
export const useEventDetailsMenuContext = () => useContext(EventDetailsMenuContext);

export const EventDetailsMenuProvider = ({ children }: { children: ReactNode }) => {
  const [selectedEvent, setSelectedEvent] = useState<RBCEvent>();
  const [visibleMenuMode, setVisibleMenuMode] = useState<EventDetailsMenuMode | undefined>();
  const { onClickScheduledEnergyboost } = useEnergyBoost();
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const { user } = useAuthContext();

  const { updateCalendarEvent, status: eventUpdatingStatus } = useUpdateCalendarEvent();
  const { deleteCalendarEvent, status: eventDeletingStatus } = useDeleteCalendarEvent();
  const { mainCalendar, calendarAccountsToCreateEvents } = useCalendarContext();

  useEffect(() => {
    if (eventDeletingStatus === 'success') {
      setEventsSnackbar('Event deleted');
    } else if (eventDeletingStatus === 'pending') {
      setEventsSnackbar('Event deleting...');
    } else if (eventDeletingStatus === 'error') {
      setEventsSnackbar('Failed to delete event');
    }
  }, [eventDeletingStatus]);

  useEffect(() => {
    if (eventUpdatingStatus === 'success') {
      setEventsSnackbar('Event updated');
    } else if (eventUpdatingStatus === 'pending') {
      setEventsSnackbar('Event updating...');
    } else if (eventUpdatingStatus === 'error') {
      setEventsSnackbar('Failed to update event');
    }
  }, [eventUpdatingStatus]);

  const handleDeleteEvent = (event: RBCEvent) => {
    if (!event.calendarId) {
      return;
    }

    deleteCalendarEvent({
      userId: user.userId,
      calendarId: event.calendarId,
      eventId: event.eventId,
    });
    clearSelectedEvent();
  };

  const handleSelectEvent = (event: RBCEvent) => {
    if (event?.eventId?.includes('energy_boost')) {
      setVisibleMenuMode('energy_boost');
      onClickScheduledEnergyboost(event);
      return;
    }
    setSelectedEvent(event);
    setVisibleMenuMode('edit');
  };

  const handleSaveEvent = (newEvent: CalendarEventModifyOption) => {
    if (!selectedEvent) {
      return;
    }
    const hasCalendarAccess = calendarAccountsToCreateEvents.some(
      ({ calendarId }) => calendarId === selectedEvent.calendarId
    );

    if (!hasCalendarAccess) {
      return;
    }

    updateCalendarEvent({
      userId: user.userId,
      calendarId: selectedEvent.calendarId ?? mainCalendar?.calendarId!,
      eventId: selectedEvent.eventId,
      newEventOption: newEvent,
    });
    clearSelectedEvent();
  };

  const handleStartEditingEvent = () => {
    setVisibleMenuMode('edit');
  };

  const clearSelectedEvent = () => {
    setSelectedEvent(undefined);
    setVisibleMenuMode(undefined);
  };

  return (
    <EventDetailsMenuContext.Provider
      value={{
        selectedEvent,
        visibleMenuMode,
        handleSaveEvent,
        handleSelectEvent,
        handleDeleteEvent,
        clearSelectedEvent,
        handleStartEditingEvent,
      }}
    >
      {children}
    </EventDetailsMenuContext.Provider>
  );
};

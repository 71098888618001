import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { exchangeCodeForToken, useAuthContext, useInitTodoIntegration } from '../../../data-access';
import './TodoistAuthCallback.scss';
import { Path } from '../../../routing/path';
import { trackEventMixpanel } from '../../../utils';

const TodoistAuthCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { initTodoIntegration } = useInitTodoIntegration();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    const exchange = async () => {
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      const params = new URLSearchParams(new URL(state).search);
      const redirectTo = params.get('redirectTo');

      if (!code) {
        return;
      }

      const token = await exchangeCodeForToken(code);
      const integrationId = await initTodoIntegration({
        token,
        todoType: 'todoist',
        userId: user.userId,
      });

      trackEventMixpanel('connect_todo_auth_done', { todoType: 'todoist' });
      navigate(redirectTo || Path.DASHBOARD);
    };

    exchange();
  }, [searchParams, user.userId]);

  return (
    <div className="todoist-auth-callback">
      <CircularProgress />
    </div>
  );
};

export default TodoistAuthCallback;

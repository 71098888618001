import { useQuery } from '@tanstack/react-query';
import { Subscription } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseSubscriptionsParams {
  userId: string;
}

interface UseSubscriptionsRes {
  subscriptions: Subscription[];
  activeSubscription?: Subscription;
  hasAccess: boolean;
}

interface UseSubscriptionsQueryParams {
  queryKey: ['lifestack.subscriptions', { userId: string }];
}

export const useSubscriptions = ({ userId }: UseSubscriptionsParams) => {
  const subscriptionQuery = useQuery({
    queryKey: ['lifestack.subscriptions', { userId }],
    queryFn: ({ queryKey }: UseSubscriptionsQueryParams) => {
      const [, { userId }] = queryKey;

      return accountApi.getSubscriptions(userId).then(({ data }) => data as UseSubscriptionsRes);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId,
    gcTime: 1000 * 60 * 60 * 24 * 30, // cache time - 30 days
    staleTime: 15000, // 15s
  });

  return {
    subscriptions: subscriptionQuery.data,
    ...subscriptionQuery,
  };
};

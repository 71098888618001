import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ConnectDevicesPage,
  ConnectTodoPage,
  EnergyPreferencePage,
  InitialSyncPage,
} from '../pages';
import { WearablesProvider } from '../data-access';

export const OnboardingRoutes: FC = () => {
  return (
    <WearablesProvider>
      <Routes>
        <Route path={'connect-devices'} element={<ConnectDevicesPage />} />
        <Route path={'initial-sync'} element={<InitialSyncPage />} />
        <Route path={'energy-preference'} element={<EnergyPreferencePage />} />
        <Route path={'connect-todo'} element={<ConnectTodoPage />} />
      </Routes>
    </WearablesProvider>
  );
};

import React, { useState, useCallback } from 'react';
import { useAuthContext, useTerraWidget, useWearablesContext } from '../../../../data-access';
import { MobileSdkOnlyWearbales, WEARABLES } from '../../constants';
import { Path } from '../../../../routing';
import './WearablesSettings.scss';
import { WearableButton } from './WearableButton';
import { SupportedTerraSDKType } from '@demind-inc/core';

const WearablesSettings: React.FC = () => {
  const { terraWidget, isPending: isGeneratingWidget } = useTerraWidget();
  const [clickedId, setClickedId] = useState<string | null>(null);
  const { user } = useAuthContext();
  const { invalidTerraUserIds, allDevices } = useWearablesContext();

  const { metricId } = user;

  const handleClick = (id: string) => {
    setClickedId(id);
    terraWidget({ metricId, redirectTo: Path.DASHBOARD });
  };

  const getIsConnected = useCallback(
    (id: string) => allDevices?.some((device) => device.terraManufacturer === id),
    [allDevices]
  );

  const getHasError = useCallback(
    (id: string) => {
      const targetDevice = allDevices?.find((device) => device.terraManufacturer === id);
      if (!targetDevice) {
        return false;
      }
      return invalidTerraUserIds.includes(targetDevice.terraUserId);
    },
    [invalidTerraUserIds, allDevices]
  );

  return (
    <div className="wearables-settings">
      <p className="wearables-settings__title">Wearables</p>
      <div className="wearables-settings__grid">
        {WEARABLES.map(({ id, name, Icon }) => (
          <WearableButton
            key={id}
            id={id}
            name={name}
            Icon={Icon}
            hasError={getHasError(id)}
            isConnected={getIsConnected(id)}
            isOnlyMobileSdkSupport={MobileSdkOnlyWearbales.includes(id as SupportedTerraSDKType)}
            isLoading={isGeneratingWidget && clickedId === id}
            onClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};

export default WearablesSettings;

import clsx from 'clsx';
import React, { FC } from 'react';

interface DataTaskDashboardProps {
  className?: string;
}

const DataTaskDashboard: FC<DataTaskDashboardProps> = ({ className }) => {
  return <div className={clsx(className)}>DataTaskDashboard</div>;
};

export default DataTaskDashboard;

import { EventCategoryEnergyPreferenceLevel } from '@demind-inc/core';

export const defaultEnergyPref: Record<string, EventCategoryEnergyPreferenceLevel> = {
  default_deepWork: 'less_energized',
  default_lightTask: 'about_same',
  default_exercise: 'more_energized',
  default_mtg: 'less_energized',
  default_social: 'more_energized',
};

export const eventCategoriesExample: Record<string, string> = {
  default_deepWork: 'Writing, coding, creative work, etc.',
  default_lightTask: 'Responding to emails, administrative tasks, etc.',
  default_social: 'Hang outs, calling friends, etc.',
};

import { useAuthContext } from '../../../../../../../data-access';
import { useSleepTrend } from '../../../../../../../hooks/useSleepTrend';
import { chronotypeTitleSet } from '../../../../constants';
import { SleepBar } from './SleepBar';
import './SleepAnalysis.scss';
import { ChevronRight } from '@mui/icons-material';
import { ReactComponent as ChronoIcon } from '../../../../../../../assets/actions/chronotype.svg';
import { ReactComponent as MoonIcon } from '../../../../../../../assets/actions/moon.svg';
import { ReactComponent as EditIcon } from '../../../../../../../assets/actions/edit.svg';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const SleepAnalysis = () => {
  const { user } = useAuthContext();
  const hasChronotype = !!user.chronotype;
  const { targetSleepStage, targetSleepHours, hasNoSleepData, targetAwakeTime, targetSleepTime } =
    useSleepTrend();

  return (
    <div className="sleep-analysis">
      <div className="sleep-analysis__title-container">
        <p className="sleep-analysis__title">Sleep</p>
        <ChevronRight className="sleep-analysis__chevron-right" />
      </div>
      <div className="sleep-analysis__chronotype-hours">
        <div className="sleep-analysis__chronotype-hours__title">
          <ChronoIcon />
          <p>{hasChronotype ? chronotypeTitleSet[user.chronotype!] : 'Chronotype'}</p>
          <HelpOutlineOutlinedIcon className="sleep-analysis__chronotype-hours__icon" />
        </div>
      </div>
      <div className="sleep-analysis__divider" />
      <div className="sleep-analysis__sleep-duration">
        <div className="sleep-analysis__sleep-duration-info">
          <MoonIcon />
          {hasNoSleepData ? '--h --m' : `${targetSleepHours.hours}h ${targetSleepHours.minutes}m`}
        </div>
        <EditIcon />
      </div>
      <SleepBar
        sleepStage={targetSleepStage}
        awakeTime={targetAwakeTime}
        sleepTime={targetSleepTime}
      />
    </div>
  );
};

export default SleepAnalysis;

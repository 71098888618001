import React, { useMemo } from 'react';

import './PeakEnergy.scss';
import { ReactComponent as EnergyIcon } from '../../../assets/circadian/energy.svg';
import { HelpOutline } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircadianTooltipContent } from '../CircadianTooltipContent';
import { CustomTooltip } from '../../common';
import { NoWearableChip } from '../NoWearableChip';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useCircadianContext } from '../../../data-access';

interface PeakEnergyProps {
  hasNoSleepData: boolean;
}

const PeakEnergy: React.FC<PeakEnergyProps> = ({ hasNoSleepData }) => {
  const { peakEnergy } = useCircadianContext();

  const score = useMemo(
    () => (peakEnergy ? Math.round(peakEnergy * 100).toString() : '--'),
    [peakEnergy]
  );

  return (
    <div className="peak-energy">
      <CustomTooltip title={<CircadianTooltipContent />} className="peak-energy__tooltip">
        <div className="peak-energy__score-group">
          <EnergyIcon className="peak-energy__icon" />
          <div className="peak-energy__score">{score}</div>
        </div>
      </CustomTooltip>
      {hasNoSleepData && (
        <CustomTooltip className="peak-energy__no-data__tooltip" title={<NoWearableChip />}>
          <ErrorOutlineIcon fontSize="small" className="peak-energy__no-data__tooltip__icon" />
        </CustomTooltip>
      )}
    </div>
  );
};

export default PeakEnergy;

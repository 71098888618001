import { useMutation, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { TaskError401Res, tasksApi } from '../api';
import { FirestoreTaskItem } from '../types';
import { taskErrorSnackBarAtom } from '../recoil';
import { AxiosError } from 'axios';
import { TodoIntegrationType } from '@demind-inc/core';

interface UseSyncTodoTasksByProjectsParams {
  userId: string;
  projectIds: string[];
  timezone: string;
  fullSync?: boolean;
}

export function useSyncTodoTasksByProjects() {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const syncTodoTasksMutation = useMutation({
    mutationFn: ({
      userId,
      projectIds,
      timezone,
      fullSync = false,
    }: UseSyncTodoTasksByProjectsParams) => {
      return tasksApi
        .syncTodoTasksByProjects(userId, timezone, projectIds, fullSync)
        .then(({ data }) => (data as FirestoreTaskItem[]) ?? []);
    },
    onSuccess: debounce(() => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    }, 2000),
  });

  useEffect(() => {
    const error = (syncTodoTasksMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'sync',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to sync task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'sync',
    });
  }, [syncTodoTasksMutation.error]);

  return {
    syncTodoTasks: syncTodoTasksMutation.mutateAsync,
    ...syncTodoTasksMutation,
  };
}

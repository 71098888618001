import { useMutation, useQueryClient } from '@tanstack/react-query';

import { integrationsApi } from '../api';

interface UseRemoveTodoIntegrationParams {
  userId: string;
  todoIntegrationId: string;
}

export function useRemoveTodoIntegration() {
  const queryClient = useQueryClient();

  const removeTodoIntegrationMutation = useMutation({
    mutationFn: ({ userId, todoIntegrationId }: UseRemoveTodoIntegrationParams) => {
      return integrationsApi.removeTodoIntegration(userId, todoIntegrationId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todoIntegrations'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
  });

  return {
    removeTodoIntegration: removeTodoIntegrationMutation.mutateAsync,
    ...removeTodoIntegrationMutation,
  };
}

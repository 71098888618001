import clsx from 'clsx';
import React, { FC } from 'react';

interface DataSleepDashboardProps {
  className?: string;
}

const DataSleepDashboard: FC<DataSleepDashboardProps> = ({ className }) => {
  return (
    <div className={clsx(className)}>
      DataSleepDashboard
    </div>
  );
};

export default DataSleepDashboard;
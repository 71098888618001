import { CalendarType, SupportedTerraProvidersType, SupportedTerraSDKType } from '@demind-inc/core';

import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';
import { ReactComponent as ChromeExtensionIcon } from '../../assets/icons/chrome-extension-icon.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile-icon.svg';
import { ReactComponent as SubscriptionIcon } from '../../assets/icons/subscription-icon.svg';
import { ReactComponent as TodoIcon } from '../../assets/icons/todo-icon.svg';
import { ReactComponent as WearableIcon } from '../../assets/icons/wearable-icon.svg';
import { ReactComponent as HealthIcon } from '../../assets/settings/wearable/health-icon.svg';
import { ReactComponent as GarminIcon } from '../../assets/settings/wearable/garmin-icon.svg';
import { ReactComponent as WhoopIcon } from '../../assets/settings/wearable/whoop-icon.svg';
import { ReactComponent as FitbitIcon } from '../../assets/settings/wearable/fitbit-icon.svg';
import { ReactComponent as UltrahumanIcon } from '../../assets/settings/wearable/ultrahuman.svg';
import { ReactComponent as GoogleFitIcon } from '../../assets/settings/wearable/google-fit.svg';
import { ReactComponent as SamsungHealthIcon } from '../../assets/settings/wearable/samsung-icon.svg';
import { ReactComponent as OuraIcon } from '../../assets/settings/wearable/oura-icon.svg';
import { ReactComponent as EightSleepIcon } from '../../assets/settings/wearable/eight-sleep.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-icon.svg';
import { ReactComponent as OutlookIcon } from '../../assets/icons/outlook-icon.svg';
import { ReactComponent as TimeFormatIcon } from '../../assets/icons/time-icon.svg';

import { AccountSettings } from './SettingsModal/AccountSettings';
import { CalendarsSettings } from './SettingsModal/CalendarSettings';
import { WearablesSettings } from './SettingsModal/WearablesSettings';
import { TodoIntgSettings } from './SettingsModal/TodoIntgSettings';
import { SubscriptionSettings } from './SettingsModal/SubscriptionSettings';
import { NotificationSettings } from './SettingsModal/NotificationSettings';
import { MobileApp } from './SettingsModal/MobileApp';
import { InstallExtension } from './SettingsModal/InstallExtension';
import { AboutSettings } from './SettingsModal/AboutSettings';
import { FC, ReactElement, SVGProps } from 'react';
import { TimeFormatToggle } from './SettingsModal/TimeFormatToggle';
import { timeFormat } from 'd3';

export interface SettingsSectionItemComponent {
  Component: React.ComponentType;
  key: string;
}

export interface SettingsSectionItem {
  title: string;
  components: SettingsSectionItemComponent[];
}

export interface WearbaleItem {
  id: SupportedTerraProvidersType | SupportedTerraSDKType;
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export interface CalendarOptionsType {
  calendar: string;
  id: CalendarType;
  icon: React.ComponentType;
}

export const ICONS = {
  mobile: require('../../assets/icons/mobile_icon.png'),
  chrome: require('../../assets/icons/chrome_icon.png'),
  close: require('../../assets/icons/close_icon.png'),
  todoist: require('../../assets/todoIntegrations/todoist_icon.png'),
  trello: require('../../assets/todoIntegrations/trello_icon.png'),
  ticktick: require('../../assets/todoIntegrations/ticktick_icon.jpg'),
};

export const SVG_ICONS = {
  calendar: CalendarIcon,
  chromeExtension: ChromeExtensionIcon,
  mobile: MobileIcon,
  notifications: NotificationsIcon,
  profile: ProfileIcon,
  subscription: SubscriptionIcon,
  todo: TodoIcon,
  wearable: WearableIcon,
  google: GoogleIcon,
  outlook: OutlookIcon,
  timeFormat: TimeFormatIcon,
};

export const WEARABLES: WearbaleItem[] = [
  { id: 'APPLE', name: 'Health', Icon: HealthIcon },
  { id: 'GOOGLE_FIT', name: 'Google Fit', Icon: GoogleFitIcon },
  { id: 'SAMSUNG_HEALTH', name: 'Samsung Health', Icon: SamsungHealthIcon },
  { id: 'GARMIN', name: 'Garmin', Icon: GarminIcon },
  { id: 'WHOOP', name: 'Whoop', Icon: WhoopIcon },
  { id: 'FITBIT', name: 'Fitbit', Icon: FitbitIcon },
  { id: 'OURA', name: 'Oura', Icon: OuraIcon },
  { id: 'EIGHTSLEEP', name: 'Eight Sleep', Icon: EightSleepIcon },
  { id: 'ULTRAHUMAN', name: 'Ultrahuman', Icon: UltrahumanIcon },
];

export const MobileSdkOnlyWearbales: SupportedTerraSDKType[] = [
  'APPLE',
  'GOOGLE_FIT',
  'SAMSUNG_HEALTH',
];

export const settingsSections: SettingsSectionItem[] = [
  {
    title: 'Account',
    components: [
      { Component: AccountSettings, key: 'profile' },
      { Component: CalendarsSettings, key: 'calendars' },
      { Component: WearablesSettings, key: 'wearables' },
      { Component: TodoIntgSettings, key: 'todos' },
      { Component: SubscriptionSettings, key: 'subscription' },
    ],
  },
  {
    title: 'General',
    components: [
      { Component: TimeFormatToggle, key: 'timeFormat' },
      { Component: NotificationSettings, key: 'notifications' },
    ],
  },
  {
    title: 'Product Hub',
    components: [
      { Component: MobileApp, key: 'mobileApp' },
      { Component: InstallExtension, key: 'chromeExtension' },
    ],
  },
  {
    title: `Lifestack ${process.env.REACT_APP_VERSION}`,
    components: [{ Component: AboutSettings, key: 'about' }],
  },
];

export const calendars: CalendarOptionsType[] = [
  { calendar: 'Google', id: 'google', icon: SVG_ICONS.google },
  { calendar: 'Outlook', id: 'outlook', icon: SVG_ICONS.outlook },
];

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SleepDetailsByDate } from '@demind-inc/core';

import { metricsApi } from '../api';

interface UseUpdateSleepDetailsParams {
  userId: string;
  metricId: string;
  detailsByDate: SleepDetailsByDate[];
}

export function useUpdateSleepDetails() {
  const queryClient = useQueryClient();

  const updateSleepDetailMutation = useMutation({
    mutationFn: ({ userId, metricId, detailsByDate }: UseUpdateSleepDetailsParams) => {
      return metricsApi.updateSleepDetails(metricId, userId, detailsByDate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.sleep.details'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
    },
  });

  return {
    updateSleepDetail: updateSleepDetailMutation.mutateAsync,
    ...updateSleepDetailMutation,
  };
}

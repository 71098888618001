import './EnergyBoostModalView.scss';
import Background from '../../../../../../assets/actions/energy-boost-showcase.png';
import { useEnergyBoost } from '../../../../../../hooks';
import { LoadingButton } from '@mui/lab';
import { FC } from 'react';

const EnergyBoostModalView: FC = () => {
  const { runEnergyBoost, isBoostingEnergy } = useEnergyBoost();
  return (
    <div className="energy-boost-modal">
      <img src={Background} alt="logo" className="energy-boost-modal__bg" draggable="false" />
      <div className="energy-boost-modal__description-container">
        <p className="energy-boost-modal__description">
          Automatically schedule your day with our AI, which arranges energy-boosting events on your
          calendar
        </p>
        <LoadingButton
          onClick={runEnergyBoost}
          className={isBoostingEnergy && 'energy-boost-modal__loading'}
          disabled={isBoostingEnergy}
          loading={isBoostingEnergy}
        >
          <p>Run</p>
        </LoadingButton>
      </div>
    </div>
  );
};

export default EnergyBoostModalView;

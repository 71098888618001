import React, { useState } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import axios from 'axios';

import './UnsubscribeDialog.scss';
import { useAuthContext } from '../../../../../data-access';
import { trackEventMixpanel } from '../../../../../utils';
import clsx from 'clsx';
import {
  canSendForm,
  ChurnReason,
  ChurnReasonDropdown,
  getReasonText,
} from '../ChurnReasonDropdown';

interface UnsubscribeDialogProps {
  open: boolean;
  onClose: () => void;
  onUnsubscribe: () => void;
}

const formUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSdm43pAQqJxsBlyjY3zpBWdAbQZo80-wbjcBjnS6B7I78GYjg/formResponse';

enum FormKeys {
  userId = 'entry.1601439860',
  reasons = 'entry.260788326',
}

const UnsubscribeDialog: React.FC<UnsubscribeDialogProps> = ({ open, onClose, onUnsubscribe }) => {
  const { user } = useAuthContext();

  const [feedbackAnswer, setFeedbackAnswer] = useState<ChurnReason | null>(null);

  const handleClickCancel = async () => {
    if (!feedbackAnswer || !canSendForm(feedbackAnswer)) {
      return;
    }

    const submitParams = new FormData();
    submitParams.append(FormKeys.reasons, getReasonText(feedbackAnswer, 'afterPaywall'));
    submitParams.append(FormKeys.userId, user.userId);

    try {
      await axios.post(formUrl, submitParams);
    } catch (error) {
      console.error('Error', 'Failed to submit feedback', error);
    } finally {
      trackEventMixpanel('cancel_subscription', { from: 'web' });
      onClose();
      onUnsubscribe();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="unsubscribe-dialog">
      <DialogTitle>Cancel your subscription</DialogTitle>
      <DialogContent>
        <DialogContent style={{ flexDirection: 'column', display: 'flex', gap: 10 }}>
          <DialogContentText>Are you sure you want to cancel your subscription?</DialogContentText>
          <ChurnReasonDropdown where="afterPaywall" onSelectReason={setFeedbackAnswer} />
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="unsubscribe-dialog__discard">
          Discard
        </Button>
        <Button
          onClick={handleClickCancel}
          disabled={!canSendForm(feedbackAnswer)}
          className={clsx(
            'unsubscribe-dialog__cancel',
            !canSendForm(feedbackAnswer) && 'unsubscribe-dialog__cancel--disabled'
          )}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsubscribeDialog;

import React, { createContext, useContext, ReactNode, useCallback, useMemo } from 'react';
import { Device } from '@demind-inc/core';

import { useDevices, useTerraStatus } from '../queries';
import { useAuthContext } from '../context';

interface WearablesContextProps {
  allDevices: Device[];

  terraUserIds: string[];
  invalidTerraUserIds: string[];
}

const WearablesContext = createContext({} as WearablesContextProps);
export const useWearablesContext = () => useContext(WearablesContext);

export const WearablesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const { devices, terraUserIds } = useDevices({
    deviceGroupId: user.deviceGroupId!,
  });

  const { terraStatus } = useTerraStatus({ terraUserIds: terraUserIds });

  const invalidTerraUserIds = useMemo(
    () => terraStatus.filter(({ valid }) => !valid).map(({ terraUserId }) => terraUserId),
    [terraStatus]
  );

  return (
    <WearablesContext.Provider
      value={{
        allDevices: devices,
        terraUserIds,
        invalidTerraUserIds,
      }}
    >
      {children}
    </WearablesContext.Provider>
  );
};

import { CalendarEvent, ScheduledAction, ScheduledActionOptions, TaskItem } from '@demind-inc/core';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);
import { v4 as uuidv4 } from 'uuid';
export const convertTaskToEvent = (task: TaskItem): CalendarEvent => {
  return {
    eventId: 'creating',
    summary: task.name,
    description: task.desc,
    color: task.labels?.[0]?.color,
    start: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    taskFrom: {
      taskId: task.taskId,
      boardId: task.boardId!,
      from: task.appFrom,
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
  };
};

/**
 * Translates the scheduled action options to a temporal calendar event.
 * @param actionOptions - The scheduled action options.
 * @returns The translated calendar event.
 */
export const translateScheduledActionOptionsToTemporalCalendarEvent = (
  actionOptions: ScheduledActionOptions
): CalendarEvent => {
  return {
    eventId: `energy_boost_${actionOptions.startDate}`,
    summary: 'Click to accept [Energy Boost]',
    start: {
      date: actionOptions.startDate,
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: actionOptions.endDate,
      timeZone: dayjs.tz.guess(),
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
  };
};

/**
 * Translates a ScheduledAction object to a CalendarEvent object.
 * @param action - The ScheduledAction object to be translated.
 * @returns The translated CalendarEvent object.
 */
export const translateScheduledActionToCalendarEvent = (action: ScheduledAction): CalendarEvent => {
  return {
    eventId: (uuidv4() as string).replace(/-/g, ''),
    summary: action.name,
    description: action.description!,
    start: {
      date: action.startDate,
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: action.endDate,
      timeZone: dayjs.tz.guess(),
    },
    status: 'confirmed',
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
    eventMetrics: {
      energy: 'high',
    },
    recommendedTaskId: action.taskId,
  };
};

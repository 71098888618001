import { Calendar, CalendarEvent } from '@demind-inc/core';
import { RBCEvent } from './types';
import { getCssVariable } from '../../../utils';

const primaryColor = getCssVariable('--color-primary');
const whiteLightest = getCssVariable('--color-white-lightest');

export const transformEventToRBCEvent = (
  event: CalendarEvent,
  targetCalendar?: Calendar
): RBCEvent => {
  const meAttendee = event.attendees?.find((attendee) =>
    [targetCalendar?.internalGCalendarId, targetCalendar?.rootEmail].includes(attendee.email)
  );
  const isDeclined = meAttendee ? meAttendee.responseStatus === 'declined' : false;

  return {
    ...event,
    title: event.summary,
    start: new Date(event.start.date),
    end: new Date(event.end.date),
    allDay: event.isAllDay,
    calendarColor: targetCalendar?.color!,
    isDeclined,
  };
};

export const transformCreatingEventToRBCEvent = (event: Partial<CalendarEvent>): RBCEvent => {
  return {
    ...event,
    ...(event.start ? { start: new Date(event.start.date) } : {}),
    ...(event.end ? { end: new Date(event.end.date) } : {}),
  } as RBCEvent;
};

export const transfomrAutoScheduledEvents = (
  events: CalendarEvent[],
  calendarColor: string = ''
): RBCEvent[] => {
  return events.map(
    ({
      start,
      end,
      summary,
      eventId,
      description,
      color,
      calendarId,
      updatedDate,
      createdDate,
    }) => ({
      start: new Date(start.date),
      end: new Date(end.date),
      title: summary,
      eventId,
      updatedDate,
      createdDate,
      description,
      color,
      calendarId,
      calendarColor,
    })
  );
};

export const getEventStyles = (rbcEvent: RBCEvent) => {
  const baseStyles = { style: { padding: 0 } };

  if (!rbcEvent.eventId) {
    return {
      style: {
        backgroundColor: `${rbcEvent.color}1A`,
        border: `1px solid ${rbcEvent.color}`,
        borderRadius: '0',
        borderLeft: 'none',
        borderRight: 'none',
      },
    };
  }

  const isCreatingEvent = rbcEvent.eventId === 'creating';
  const isAutoScheduledEvent = rbcEvent.eventId.includes('energy_boost');

  if (isAutoScheduledEvent) {
    return {
      style: {
        ...baseStyles.style,
        backgroundColor: whiteLightest,
        border: `1px solid ${rbcEvent.calendarColor}`,
      },
    };
  }

  return {
    style: {
      ...baseStyles.style,
      backgroundColor: isCreatingEvent ? 'white' : `${rbcEvent.color || primaryColor}33`,
      border: isCreatingEvent ? `solid 1px ${primaryColor}` : 'none',
    },
  };
};

import { FC } from 'react';
import { Check } from '@mui/icons-material';
import clsx from 'clsx';

import './SubscriptionPlanContent.scss';
import { DEFAULT_FREE_TRIAL_DAYS } from '../../../data-access';

interface SubscriptionPlanContentProps {
  className?: string;
  freeTrialDays?: number;
  hideTrial?: boolean;
}

const SubscriptionPlanContent: FC<SubscriptionPlanContentProps> = ({
  className,
  freeTrialDays = DEFAULT_FREE_TRIAL_DAYS,
  hideTrial = false,
}) => {
  return (
    <div className={clsx('subscription-plan-content', className)}>
      {!hideTrial && (
        <div className="subscription-plan-content__item">
          <Check className="subscription-plan-content__item__check" />
          <div className="subscription-plan-content__item__title">
            {freeTrialDays}-day free trial
          </div>
        </div>
      )}
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">Become more productive</div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">Avoid burnout</div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">
          Enjoy Lifestack on multiple platforms (mobile, web, and chrome extension)
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanContent;

import { useEffect, useMemo } from 'react';
import { CalendarEvent, ScheduledAction } from '@demind-inc/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  energyboostOptionsTimelineAtom,
  selectedCoreActionModalAtom,
  selectedDateAtom,
  selectedEnergyBoostOptionsAtom,
  useAuthContext,
  useCalendarContext,
  useCircadianContext,
  useCreateCalendarEvent,
  useRecommendations,
  useScheduleEnergyBoost,
} from '../data-access';
import {
  translateScheduledActionOptionsToTemporalCalendarEvent,
  translateScheduledActionToCalendarEvent,
} from '../helpers';
import { getCssVariable } from '../utils';
import { RBCEvent } from '../components';

const PRIMARY_COLOR = getCssVariable('--color-primary');

export const useEnergyBoost = () => {
  const [energyboostOptionsTimeline, setEnergyboostOptionsTimeline] = useRecoilState(
    energyboostOptionsTimelineAtom
  );
  const [selectedEnergyboostOptions, setSelectedEnergyboostOptions] = useRecoilState(
    selectedEnergyBoostOptionsAtom
  );
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const { scheduleEnergyBoost: scheduleEnergyBoostMutation, isPending: isBoostingEnergy } =
    useScheduleEnergyBoost();
  const { user } = useAuthContext();
  const { calendarsMeta, mainCalendar, findCalendarItem, calendarEvents } = useCalendarContext();
  const { circadianPhaseBoundariesByDate } = useCircadianContext();
  const { recommendedActionOrigin } = useRecommendations();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { createCalendarEvent } = useCreateCalendarEvent();

  const circadianPhaseBoundaries = useMemo(
    () =>
      circadianPhaseBoundariesByDate?.find(({ date }) => date === selectedDate.format('YYYY-MM-DD'))
        ?.details,
    [circadianPhaseBoundariesByDate, selectedDate]
  );

  // Filter out freeBusyReader & reader calendars, and invisible calendars
  const targetEventsOnDay = useMemo(() => {
    const targetCalendarIds = calendarsMeta
      .filter(({ scope, visible }) => !['freeBusyReader', 'reader'].includes(scope!) && visible)
      .map(({ calendarId }) => calendarId);

    return calendarEvents.filter(({ calendarId }) => targetCalendarIds.includes(calendarId!));
  }, [calendarsMeta, calendarEvents, selectedDate.toISOString()]);

  const disabledEnergyBoost = useMemo(() => !circadianPhaseBoundaries, [circadianPhaseBoundaries]);

  const { canResetScheduledEnergyboost } = useMemo(() => {
    let canResetScheduledEnergyboost = false;

    if (energyboostOptionsTimeline.length) {
      canResetScheduledEnergyboost = true;
    }

    return { canResetScheduledEnergyboost };
  }, [energyboostOptionsTimeline]);

  // Reset scheduled events when the date changes
  useEffect(() => {
    resetEnergyboost();
  }, [selectedDate]);

  const { newEventColor, newEventCalendarId } = useMemo(() => {
    const newEventCalendarId = mainCalendar?.calendarId!;
    const color = findCalendarItem(newEventCalendarId)?.color;

    return { newEventColor: color || PRIMARY_COLOR, newEventCalendarId };
  }, [mainCalendar, findCalendarItem]);

  // Convert options to calendar events type
  const scheduledEnergyboostEvents = useMemo(() => {
    return energyboostOptionsTimeline.map((option) => ({
      ...translateScheduledActionOptionsToTemporalCalendarEvent(option),
      calendarId: newEventCalendarId,
      color: newEventColor,
    }));
  }, [energyboostOptionsTimeline, newEventColor, newEventCalendarId]);

  const resetEnergyboost = () => {
    setEnergyboostOptionsTimeline([]);
    setSelectedEnergyboostOptions(undefined);
  };

  const runEnergyBoost = async () => {
    if (!circadianPhaseBoundaries) {
      return;
    }
    const recs = await scheduleEnergyBoostMutation({
      userId: user.userId,
      eventsOnDay: targetEventsOnDay,
      circadianPhaseBoundaries,
      origin: recommendedActionOrigin,
      currentTime: selectedDate.toISOString(),
    });
    setSelectedModal(null);
    setEnergyboostOptionsTimeline(recs);
  };

  const handleClickEnergyBoostBtn = () => {
    if (canResetScheduledEnergyboost) {
      resetEnergyboost();
      return;
    }

    if (isBoostingEnergy || disabledEnergyBoost) {
      return;
    }
  };

  const onClickScheduledEnergyboost = (event: RBCEvent) => {
    setSelectedEnergyboostOptions(
      // TODO: use the unique id to identify.
      energyboostOptionsTimeline.find(({ startDate }) => startDate === event.start.toISOString())
    );
  };

  const onAcceptScheduledEnergyboost = (action: ScheduledAction, calendarId?: string) => {
    const newEvent = translateScheduledActionToCalendarEvent(action);

    createCalendarEvent({
      userId: user.userId,
      calendarId: calendarId || newEventCalendarId,
      newEventOption: { ...newEvent, color: newEventColor },
      metrics: { energy: 'high' },
      taskId: action.taskId,
    });

    resetEnergyboost();
  };

  const onDiscardScheduledEnergyboost = () => {
    setEnergyboostOptionsTimeline(
      // TODO: use the unique id to identify.
      energyboostOptionsTimeline.filter(
        (action) => action.startDate !== selectedEnergyboostOptions?.startDate
      )
    );
    onCancelEnergyboostEventConfirm();
  };

  const onCancelEnergyboostEventConfirm = () => {
    setSelectedEnergyboostOptions(undefined);
  };

  return {
    selectedEnergyboostOptions,
    scheduledEnergyboostEvents,
    canResetScheduledEnergyboost,
    disabledEnergyBoost,
    isBoostingEnergy,
    handleClickEnergyBoostBtn,
    runEnergyBoost,
    onCancelEnergyboostEventConfirm,
    onClickScheduledEnergyboost,
    onAcceptScheduledEnergyboost,
    onDiscardScheduledEnergyboost,
  };
};

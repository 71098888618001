import { useMutation } from '@tanstack/react-query';
import {
  CalendarEvent,
  PhaseStartEndSet,
  RecommendedTaskOrigin,
  ScheduledActionOptions,
} from '@demind-inc/core';
import dayjs from 'dayjs';

import { schedulerApi } from '../api';

interface UseSchedulerEnergyBoostParams {
  userId: string;
  eventsOnDay: CalendarEvent[];
  circadianPhaseBoundaries: PhaseStartEndSet;
  origin?: RecommendedTaskOrigin;
  currentTime?: string;
}

export function useScheduleEnergyBoost() {
  const scheduleEnergyBoostMutation = useMutation({
    mutationFn: ({
      userId,
      eventsOnDay,
      circadianPhaseBoundaries,
      origin,
      currentTime,
    }: UseSchedulerEnergyBoostParams) => {
      return schedulerApi
        .scheduleEnergyBoost(userId, {
          eventsOnDay,
          circadianPhaseBoundaries,
          currentTime,
          origin,
        })
        .then(({ data }) => (data ?? []) as ScheduledActionOptions[]);
    },
  });

  return {
    scheduleEnergyBoost: scheduleEnergyBoostMutation.mutateAsync,
    ...scheduleEnergyBoostMutation,
  };
}

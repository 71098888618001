import { ReactNode, createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { SleepDetailsByDate, SleepStage } from '@demind-inc/core';
import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';

import { useAuthContext } from './AuthProvider';
import { useSleepDetails } from '../queries';
import { selectedDateAtom } from '../recoil';
import { useSyncSleepData } from '../../hooks';
import { TotalSleepHourAndMinute, translateSecondsToHourAndMinutes } from '../../helpers';
import { isEmpty } from 'lodash';
import { getWeekDatesOf } from '../helpers';
import { useWearablesContext } from './WearablesProvider';

interface IMetricsContext {
  sleepDetails: SleepDetailsByDate[];
  getSleepOnDate: (date: Dayjs, fromCache?: boolean) => GetSleepOnDateRes;
  isLoading: boolean;
}
interface GetSleepOnDateRes {
  totalSleepHours: TotalSleepHourAndMinute;
  hasNoSleepData: boolean;
  sleepStage?: SleepStage;
  awakeTime?: string;
  sleepTime?: string;
  sleepSource?: string;
  sleepDurationMin?: number;
  sleepEfficiency?: number;
}

export const MetricsContext = createContext({} as IMetricsContext);
export const useMetricsContext = () => useContext(MetricsContext);

export const MetricsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const syncedDates = useRef<string[]>([]);
  const { terraUserIds } = useWearablesContext();

  const { sleepDetails, isLoading: isFetchingSleep } = useSleepDetails({
    userId: user.userId,
    metricId: user.metricId!,
    dates: [selectedDate.format('YYYY-MM-DD')],
  });
  const { syncSleepData } = useSyncSleepData();

  useEffect(() => {
    if (
      !terraUserIds.length ||
      syncedDates.current.includes(selectedDate.format('YYYY-MM-DD')) ||
      selectedDate.isAfter(dayjs(), 'day')
    ) {
      return;
    }
    syncSleepData({
      terraUserIds,
      startDate: selectedDate.subtract(1, 'day').format('YYYY-MM-DD'),
      endDate: selectedDate.add(1, 'day').format('YYYY-MM-DD'),
    });
    syncedDates.current.push(selectedDate.format('YYYY-MM-DD'));
  }, [selectedDate.toISOString(), terraUserIds.length]);

  const getSleepOnDate = useCallback(
    (date: Dayjs) => {
      const sourceData = sleepDetails.find(
        (item) => item.date === date.format('YYYY-MM-DD')
      )?.details;
      const totalSleepHours = translateSecondsToHourAndMinutes((sourceData?.durationMin ?? 0) * 60);
      const hasNoSleepData = isFetchingSleep ? false : !sleepDetails || isEmpty(sleepDetails);
      const sleepStage = sourceData?.stages;
      const awakeTime = sourceData?.awakeTime;
      const sleepTime = sourceData?.sleepTime;
      const sleepSource = sourceData?.source;
      const sleepDurationMin = sourceData?.durationMin;
      const sleepEfficiency = sourceData?.efficiency;

      return {
        sleepStage,
        awakeTime,
        sleepTime,
        sleepSource,
        sleepDurationMin,
        totalSleepHours,
        sleepEfficiency,
        hasNoSleepData,
      };
    },
    [sleepDetails, isFetchingSleep]
  );
  return (
    <MetricsContext.Provider
      value={{
        sleepDetails,
        isLoading: isFetchingSleep,
        getSleepOnDate,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

import { FC } from 'react';
import { Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { BorderColor } from '@mui/icons-material';

import './NoWearableChip.scss';
import { selectedCoreActionModalAtom } from '../../../data-access';
import { ACTIONS } from '../../KanbanView/CoreActions/constants';

const NoWearableChip: FC = () => {
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);

  return (
    <div className="no-wearable-chip">
      <div className="no-wearable-chip__text">Sleep Data Not Recorded</div>
      <div className="no-wearable-chip__cta">
        <div className="no-wearable-chip__cta__sync">
          Open your wearable's app to sync your latest sleep data (syncing may take some time)
        </div>
        <Button
          variant="text"
          size="small"
          onClick={() => setSelectedModal(ACTIONS.find((action) => action.id === 'add_sleep'))}
        >
          <BorderColor fontSize="small" />
          Add sleep manually
        </Button>
      </div>
    </div>
  );
};

export default NoWearableChip;

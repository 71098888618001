import clsx from 'clsx';
import React, { FC } from 'react';

interface DataCircadianDashboardProps {
  className?: string;
}

const DataCircadianDashboard: FC<DataCircadianDashboardProps> = ({ className }) => {
  return <div className={clsx(className)}>DataCircadianDashboard</div>;
};

export default DataCircadianDashboard;

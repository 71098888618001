import { useQuery } from '@tanstack/react-query';

import { accountApi } from '../api';
import { Device, DevicePriorities } from '@demind-inc/core';

interface UseDevicesParams {
  deviceGroupId: string;
}

interface UseDevicesQueryParams {
  queryKey: ['lifestack.devices', UseDevicesParams];
}

export const useDevices = ({ deviceGroupId }: UseDevicesParams) => {
  const devicesQuery = useQuery({
    queryKey: ['lifestack.devices', { deviceGroupId }],
    queryFn: ({ queryKey }: UseDevicesQueryParams) => {
      const [, { deviceGroupId }] = queryKey;
      return accountApi.getDevices(deviceGroupId).then(({ data }) => data);
    },
    refetchOnWindowFocus: false,
    staleTime: 15000, // 15s
    enabled: !!deviceGroupId,
  });

  return {
    devices: (devicesQuery.data?.devices ?? []) as Device[],
    sleepPriorities: devicesQuery.data?.sleepPriorities
      ? (devicesQuery.data.sleepPriorities as DevicePriorities)
      : undefined,
    stressPriorities: devicesQuery.data?.stressPriorities
      ? (devicesQuery.data.stressPriorities as DevicePriorities)
      : undefined,
    terraUserIds: devicesQuery.data?.terraUserIds ?? [],
    ...devicesQuery,
  };
};

import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { sum } from 'lodash';

import { BASIC_AWAKE_TIME, BASIC_SLEEP_TIME, BASIC_SLEEP_TIME_12H } from '../../../../../constants';
import { getCssVariable } from '../../../../../../../../utils';
import { useGeneralSettings } from '../../../../../../../../hooks/useGeneralSettings';
import './SleepBar.scss';
import { Tooltip } from '@mui/material';

const colorBlueBase = getCssVariable('--color-blue-base');
const colorGreenBase = getCssVariable('--color-green-base');
const colorOrangeBase = getCssVariable('--color-orange-base');
const colorBlueLight = getCssVariable('--color-blue-light');

const sleepSummaryColor = {
  deep: colorBlueBase,
  light: colorBlueLight,
  rem: colorOrangeBase,
  awake: colorGreenBase,
};

interface SleepBarProps {
  sleepStage?: Record<string, number>;
  awakeTime?: string;
  sleepTime?: string;
}

const SleepBar: React.FC<SleepBarProps> = ({ sleepStage, awakeTime, sleepTime }) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const calcWidth = (value: number) => {
    const totalWidth = Object.values(sleepStage ?? {}).reduce((acc, curr) => acc + curr, 0);
    return Math.round((value / totalWidth) * 100);
  };

  const hasNoValidStage = useMemo(() => sum(Object.values(sleepStage ?? {})) === 0, [sleepStage]);

  const { sleepTimeString, awakeTimeString } = useMemo(() => {
    let sleepTimeString = '';
    let awakeTimeString = '';

    if (sleepTime) {
      sleepTimeString = dayjs(sleepTime).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');
    } else {
      sleepTimeString =
        timeFormat === '24h'
          ? `0${BASIC_SLEEP_TIME}:00 (default)`
          : `${BASIC_SLEEP_TIME_12H}AM (default)`;
    }

    if (awakeTime) {
      awakeTimeString = dayjs(awakeTime).format(timeFormat === '24h' ? 'HH:mm' : 'h:mm A');
    } else {
      awakeTimeString =
        timeFormat === '24h'
          ? `0${BASIC_AWAKE_TIME}:00 (default)`
          : `${BASIC_AWAKE_TIME}AM (default)`;
    }

    return { sleepTimeString, awakeTimeString };
  }, [sleepTime, awakeTime, timeFormat]);

  return (
    <div className="sleep-bar">
      <div className="sleep-bar__stages">
        {!hasNoValidStage ? (
          Object.entries(sleepStage ?? {}).map(([key, value]) => {
            const width = calcWidth(value);
            if (width === 0) {
              return null;
            }
            return (
              <Tooltip title={`${key}: ${value} min`} placement="top" arrow>
                <div
                  key={key}
                  className="sleep-bar__stage"
                  style={{
                    width: `${width}%`,
                    backgroundColor: sleepSummaryColor[key as keyof typeof sleepSummaryColor],
                  }}
                />
              </Tooltip>
            );
          })
        ) : (
          <div className="sleep-bar__stage sleep-bar__stage--empty" />
        )}
      </div>
      <div className="sleep-bar__times">
        <span className="sleep-bar__time">{sleepTimeString}</span>
        <span className="sleep-bar__time">{awakeTimeString}</span>
      </div>
    </div>
  );
};

export default SleepBar;

import { useRecoilState } from 'recoil';
import { ReactComponent as DataIcon } from '../../assets/views/data.svg';
import { ReactComponent as HomeIcon } from '../../assets/views/home.svg';
import { viewSwitcherAtom } from '../../data-access';
import clsx from 'clsx';
import './ViewSwitcher.scss';
import { FC } from 'react';

const ViewSwitcher: FC = () => {
  const [currentView, setCurrentView] = useRecoilState(viewSwitcherAtom);

  return (
    <div className="view-switcher">
      <div
        className={clsx('view-switcher__indicator', {
          'view-switcher__indicator--right': currentView === 'Data',
        })}
      />
      <button
        className={clsx('view-switcher__button', {
          'view-switcher__button--active': currentView === 'Home',
        })}
        onClick={() => setCurrentView('Home')}
      >
        <HomeIcon />
      </button>
      <button
        className={clsx('view-switcher__button', {
          'view-switcher__button--active': currentView === 'Data',
        })}
        onClick={() => setCurrentView('Data')}
      >
        <DataIcon />
      </button>
    </div>
  );
};

export default ViewSwitcher;

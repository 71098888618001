import { FC } from 'react';
import {
  CalendarProvider,
  CircadianProvider,
  MetricsProvider,
  TodoTasksProvider,
  WearablesProvider,
} from '../data-access';
import { Outlet } from 'react-router-dom';

export const BasisProvider: FC = () => {
  return (
    <WearablesProvider>
      <CalendarProvider>
        <TodoTasksProvider>
          <CircadianProvider>
            <MetricsProvider>
              <Outlet />
            </MetricsProvider>
          </CircadianProvider>
        </TodoTasksProvider>
      </CalendarProvider>
    </WearablesProvider>
  );
};

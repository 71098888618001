import { StepProgress } from './StepProgress';
import './DragAndDropPractice.scss';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { PracticeCalendar } from './PracticeCalendar';
import { useEffect, useState, FC } from 'react';
import { CircadianProvider, TodoTasksProvider, useTodoTasksContext } from '../../../data-access';
import clsx from 'clsx';
import { dummyTasks } from '../constants';
import { KanbanList } from '../../KanbanView';
import dayjs from 'dayjs';

interface DragAndDropPracticeProps {
  onShowPractice: (visible: boolean) => void;
}

const dummyDates = [dayjs(), dayjs().add(1, 'day')];

const DragAndDropPractice: FC<DragAndDropPracticeProps> = ({ onShowPractice }) => {
  const [step, setStep] = useState(1);
  const dummyArrayBasedOnStep = step > 1 ? dummyTasks : dummyTasks.slice(0, 1);
  const [initialDummyTasks, setInitialDummyTasks] = useState(dummyArrayBasedOnStep);

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        onShowPractice(false);
      }, 1000);
    }
  }, [step]);

  return (
    <TodoTasksProvider>
      <CircadianProvider>
        <DndProvider backend={HTML5Backend}>
          <div className="drag-and-drop-practice">
            <div className="drag-and-drop-practice__overlay">
              <div className="drag-and-drop-practice__modal">
                <div className="drag-and-drop-practice__header">
                  <p className="drag-and-drop-practice__header-text">
                    Great! Let's do 2 practices.
                  </p>
                  <StepProgress step={step} />
                </div>
                <div className="drag-and-drop-practice__content">
                  {dummyDates.map((listDate) => (
                    <KanbanList
                      key={listDate.toISOString()}
                      date={listDate}
                      tasks={initialDummyTasks}
                      isFetchingTasks={false}
                      className="practice-list"
                      onMoveTask={() => void 0}
                      isDemo={true}
                    />
                  ))}

                  <PracticeCalendar
                    onChangeInitialDummyTasks={setInitialDummyTasks}
                    initialDummyTasks={initialDummyTasks}
                    onMoveStep={() => setStep(step + 1)}
                    step={step}
                  />
                </div>
                <div className="drag-and-drop-practice__modal__stepper-container">
                  {new Array(2).fill(0).map((_, index) => (
                    <div
                      className={clsx('drag-and-drop-practice__modal__stepper-container__stepper', {
                        'drag-and-drop-practice__modal__stepper-container__stepper--active':
                          (index === 0 && step === 1) || (index === 1 && step >= 2),
                      })}
                    />
                  ))}
                </div>
                <span
                  className="drag-and-drop-practice__skip"
                  onClick={() => onShowPractice(false)}
                >
                  Skip
                </span>
              </div>
            </div>
          </div>
        </DndProvider>
      </CircadianProvider>
    </TodoTasksProvider>
  );
};

export default DragAndDropPractice;

import { useEffect, useState } from 'react';
import { FeatureFlags } from '@demind-inc/core';
import { fetchAndActivate, getAll, Value } from 'firebase/remote-config';

import { remoteConfig } from '../data-access';
import { isDevelopment } from './environment';

//@link: https://www.notion.so/demind-inc/Feature-Flags-2327fe3c821a444d9076b771c606c0be

export const defaultRemoteConfig: FeatureFlags = {
  force_update_version: '1.0.0',
  FF_calendly: false,
  FF_core_action_web: true,
  FF_30_day_trial: false,
  FF_data_screen_web: false,
  FF_basic_price_down: false,
};

export const useFeatureFlag = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultRemoteConfig);

  useEffect(() => {
    const init = async () => {
      if (isDevelopment) {
        // Use customized local feature flags for development
        return;
      }

      await fetchAndActivate(remoteConfig);
      remoteConfig.defaultConfig = defaultRemoteConfig;

      const ff = getAll(remoteConfig);

      setFeatureFlags({
        FF_calendly: ff.FF_calendly.asBoolean(),
        FF_core_action_web: ff.FF_core_action_web.asBoolean(),
        FF_30_day_trial: ff.FF_30_day_trial.asBoolean(),
        force_update_version: ff.force_update_version.asString(),
        FF_data_screen_web: ff.FF_data_screen_web.asBoolean(),
      });
    };

    init();
  }, []);

  return { featureFlags };
};

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Typography } from '@mui/material';

import './InitialSyncPage.scss';
import SignInAsset from '../../../assets/onboarding/signin.png';

import { OnboardingContainer } from '../../../components';
import { useInitSleepData } from '../../../hooks';
import { useAuthContext, useUpdateOnboardingStatus } from '../../../data-access';
import { Path } from '../../../routing/path';

const InitialSyncPage: React.FC = () => {
  const { syncSleepData } = useInitSleepData();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if (!user.userId) {
        return;
      }
      await syncSleepData();
      updateOnboardingStatus({
        userId: user.userId,
        newStatus: { initialSync: 'answered' },
      });
      navigate(Path.DASHBOARD);
    };

    init();
  }, [user.userId]);

  return (
    <div className="initial-sync-page">
      <OnboardingContainer
        leftChildren={
          <img
            src={SignInAsset}
            alt="lifestck signin"
            className="initial-sync-page__left-section__asset"
          />
        }
        rightChildren={
          <div className="initial-sync-page__right-section">
            <Typography variant="h2" className="initial-sync-page__title">
              Initializing data to personalize your energy
            </Typography>
            <LinearProgress color="primary" className="initial-sync-page__progress-bar" />
          </div>
        }
      />
    </div>
  );
};

export default InitialSyncPage;

import React, { FC } from 'react';
import { DataViewMenuItems } from '../constants';
import './MenuBar.scss';
import { useRecoilState } from 'recoil';
import { selectedDataMenuItem } from '../../../data-access';
import clsx from 'clsx';

interface MenuBarProps {
  className?: string;
}

const MenuBar: FC<MenuBarProps> = ({ className }) => {
  const [_, setSelectedDataMenuItem] = useRecoilState(selectedDataMenuItem);
  return (
    <div className={clsx('data-menu-bar', className)}>
      <div className="data-menu-bar__menu-container">
        {DataViewMenuItems.map((item) => (
          <div onClick={() => setSelectedDataMenuItem(item.id)} className="data-menu-bar__menu">
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuBar;

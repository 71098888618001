import { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Stripe from 'stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import clsx from 'clsx';

import './PaywallPage.scss';
import PaywallAsset from '../../../assets/onboarding/paywall.png';
import {
  DeleteAccountDialog,
  OnboardingContainer,
  SubscriptionPlanContent,
  SubscriptionPlanItem,
  SubscriptionTimeline,
} from '../../../components';
import {
  basicOfferingId,
  basicPriceDownOfferingId,
  DEFAULT_FREE_TRIAL_DAYS,
  MONTH_FREE_TRIAL_DAYS,
  useAuthContext,
  useAvailableSubscriptions,
  useReferralCampaign,
  useSubscriptionContext,
} from '../../../data-access';
import { translatePlanPriceDetail } from './helpers';
import { CardPayment } from './CardPayment';
import { useBreakpoints, useFeatureFlag, useQueryString } from '../../../utils';
import { Button, CircularProgress, Typography } from '@mui/material';
import { getMetaDataFromStripeSubscription } from '../../../helpers';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaywallPage: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Stripe.Price | undefined>();
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);

  const { signOut } = useAuthContext();
  const {
    featureFlags: { FF_30_day_trial, FF_basic_price_down },
  } = useFeatureFlag();

  const { availableSubscriptions, isLoading: isFetchingPlans } = useAvailableSubscriptions({
    provider: 'stripe',
    access: 'basic',
  }); // Web supports only Stripe payment.
  const { rewardfulCaompaignId, hasExpiredSubscription } = useSubscriptionContext();
  const { queryString: referralQSParam } = useQueryString({ qskey: 'via' });
  const { referralCampaign } = useReferralCampaign({ campaignId: rewardfulCaompaignId });
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoints();

  //@link: https://www.notion.so/demind-inc/Change-pricing-trial-period-9758450c91384f1693ed53b3f5ab8ba9
  const availableSubscriptionsFilteredPriceAB = useMemo(
    () =>
      availableSubscriptions.filter((plan) =>
        FF_basic_price_down
          ? plan.metadata.offeringId === basicPriceDownOfferingId
          : plan.metadata.offeringId === basicOfferingId
      ),
    [FF_basic_price_down, availableSubscriptions]
  );

  const handleGoback = () => {
    navigate(-1);
  };

  const handleSignOut = () => {
    signOut();
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 1000);
  };

  useEffect(() => {
    if (!referralQSParam) {
      return;
    }
    // Update the URL with the referral code
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('via', referralQSParam);
    window.history.pushState({}, '', currentUrl.href);

    // Reload the page to ensure to apply the referral code
    const hasReloaded = JSON.parse(localStorage.getItem('hasReloaded') || 'false') as boolean;
    if (hasReloaded) {
      return;
    }
    localStorage.setItem('hasReloaded', 'true');
    setTimeout(() => window.location.reload(), 1000);
  }, [referralQSParam]);

  // Set the default plan to annual plan
  useEffect(() => {
    if (!availableSubscriptionsFilteredPriceAB.length) {
      return;
    }
    setSelectedPlan(
      availableSubscriptionsFilteredPriceAB.find(({ recurring }) => recurring.interval === 'year')
    );
  }, [availableSubscriptionsFilteredPriceAB]);

  const freeTrialDays = useMemo(() => {
    if (referralCampaign && referralCampaign.offer.freeTrialDays) {
      return referralCampaign.offer.freeTrialDays;
    }
    if (FF_30_day_trial) {
      return MONTH_FREE_TRIAL_DAYS;
    }
    if (!selectedPlan) {
      return DEFAULT_FREE_TRIAL_DAYS;
    }
    return getMetaDataFromStripeSubscription(selectedPlan).freeTrialDays;
  }, [selectedPlan, referralCampaign, FF_30_day_trial]);

  const offeringId = useMemo(
    () => selectedPlan?.metadata.offeringId || basicOfferingId,
    [selectedPlan]
  );

  const renderBottomButtonGroup = () => (
    <div className="paywall-page__bottom-group">
      <Button variant="text" onClick={handleGoback} className="paywall-page__bottom-group__text">
        Go Back
      </Button>
      <Button variant="text" onClick={handleSignOut} className="paywall-page__bottom-group__text">
        Log Out
      </Button>
      <Button
        variant="text"
        onClick={() => setDeleteUserDialogVisible(true)}
        className="paywall-page__bottom-group__text"
      >
        Delete Account
      </Button>
      <DeleteAccountDialog
        visible={deleteUserDialogVisible}
        where="beforePaywall"
        onClose={() => setDeleteUserDialogVisible(false)}
      />
    </div>
  );

  return (
    <div className="paywall-page">
      <OnboardingContainer
        leftChildren={
          <img src={PaywallAsset} alt="lifestack paywall" className="paywall-page__asset" />
        }
        rightChildren={
          <div className="paywall-page__right-section">
            <div className="paywall-page__content">
              <Typography variant="h1" className="paywall-page__content__title">
                {hasExpiredSubscription ? 'Reactivate Your Subscription' : 'Start Your Free Trial'}
              </Typography>
              <div className="paywall-page__content__plans">
                {isFetchingPlans && (
                  <div className="paywall-page__content__plans--loader">
                    <CircularProgress />
                  </div>
                )}
                {!isFetchingPlans &&
                  availableSubscriptionsFilteredPriceAB.map((plan) => {
                    const recurring = plan.recurring.interval;

                    return (
                      <SubscriptionPlanItem
                        key={plan.id}
                        title={recurring === 'month' ? 'Monthly plan' : 'Annual plan'}
                        isSelected={selectedPlan?.id === plan.id}
                        isBestDeal={recurring === 'year'}
                        description={translatePlanPriceDetail(plan)}
                        onClick={() => setSelectedPlan(plan)}
                      />
                    );
                  })}
                <SubscriptionPlanContent
                  className="paywall-page__content__plans__explanation"
                  freeTrialDays={freeTrialDays}
                  hideTrial={hasExpiredSubscription}
                />

                <div className="paywall-page__content__bottom-group">
                  <SubscriptionTimeline
                    startTrialDate={
                      hasExpiredSubscription ? undefined : dayjs().format('YYYY-MM-DD')
                    }
                    startChargeDate={
                      hasExpiredSubscription
                        ? dayjs().format('YYYY-MM-DD')
                        : dayjs().add(freeTrialDays, 'day').format('YYYY-MM-DD')
                    }
                    freeTrialDays={freeTrialDays}
                    price={selectedPlan ? translatePlanPriceDetail(selectedPlan, true) : ''}
                    className="paywall-page__content__timeline"
                  />
                  <Elements stripe={stripePromise}>
                    <CardPayment
                      selectedPlan={selectedPlan}
                      freeTrialDays={freeTrialDays}
                      offeringId={offeringId}
                    />
                  </Elements>
                  {!!referralCampaign && (
                    <Typography
                      variant="body2"
                      className={clsx(
                        'paywall-page__content__referral',
                        'paywall-page__content__referral--applied'
                      )}
                    >
                      Referral code applied!
                    </Typography>
                  )}
                  {/* Deprecated (as of 2024 Oct 25) */}
                  {/* {appliedRefferalCode ? (
                    <div
                      className={clsx(
                        'paywall-page__content__referral',
                        'paywall-page__content__referral--applied'
                      )}
                    >
                      Referral code applied!
                    </div>
                  ) : (
                    <div
                      className="paywall-page__content__referral"
                      onClick={() => setIsReferralCodeDialogOpen(true)}
                    >
                      Have a referral code?
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        }
        additionalChildren={!isDesktop && renderBottomButtonGroup()}
      />
      {isDesktop && renderBottomButtonGroup()}
      {/* <ReferralCodeDialog
        open={isReferralCodeDialogOpen}
        onClose={() => setIsReferralCodeDialogOpen(false)}
        onValidate={applyReferralCode}
        isValidating={isValidatingReferral}
        error={validationError}
      /> */}
    </div>
  );
};

export default PaywallPage;

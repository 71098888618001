import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';
import { FirestoreDevice } from '../types';

interface UseUpdateUserDevicesParams {
  deviceGroupId: string;
  deviceId: string;
  device: Partial<FirestoreDevice>;
}

export const useUpdateUserDevices = () => {
  const queryClient = useQueryClient();

  const updateUserDevicesMutation = useMutation({
    mutationFn: ({ deviceGroupId, deviceId, device }: UseUpdateUserDevicesParams) => {
      return accountApi.updateDeviceV2(deviceGroupId, deviceId, device).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.devices'] });
    },
  });

  return {
    updateUserDevices: updateUserDevicesMutation.mutateAsync,
    ...updateUserDevicesMutation,
  };
};

import React from 'react';

import { DataMenuItem } from '../../data-access';
 
import { DataEventDashboard } from './DataEventDashboard';
import { DataSleepDashboard } from './DataSleepDashboard';
import { DataTaskDashboard } from './DataTaskDashboard';
import { DataCircadianDashboard } from './DataCircadianDashboard';

interface DataViewMenuItem {
  id: DataMenuItem;
  title: string;
  component: React.FC<{ className?: string }>;
}

export const DataViewMenuItems: DataViewMenuItem[] = [
  {
    id: 'dataCircadian',
    title: 'Circadian Rhythm',
    component: DataCircadianDashboard,
  },
  {
    id: 'dataTask',
    title: 'Task Completion',
    component: DataTaskDashboard,
  },
  {
    id: 'dataSleep',
    title: 'Sleep',
    component: DataSleepDashboard,
  },
  {
    id: 'dataTrend',
    title: 'Most stressful event',
    component: DataEventDashboard,
  },
];

import clsx from 'clsx';
import React, { FC } from 'react';

interface DataEventDashboardProps {
  className?: string;
}

const DataEventDashboard: FC<DataEventDashboardProps> = ({ className }) => {
  return <div className={clsx(className)}>DataEventDashboard</div>;
};

export default DataEventDashboard;

import { needDetailFieldPlaceholderSet } from './constants';
import { ChurnReason } from './types';

export const canSendForm = (reason: ChurnReason | null) => {
  if (!reason) {
    return false;
  }
  if (Object.keys(needDetailFieldPlaceholderSet).includes(reason.mainReason)) {
    return !!reason.detailReason;
  }
  return true;
};

export const getReasonText = (
  reason: ChurnReason | null,
  where: 'beforePaywall' | 'afterPaywall'
) => {
  if (!reason) {
    return '';
  }

  return `${reason.mainReason} - ${reason.detailReason} (${
    where === 'beforePaywall' ? 'Before Paywall' : 'After Paywall'
  } - Web)`;
};

import { ChangeEvent, FC, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  TextField,
  InputLabel,
} from '@mui/material';
import { ChurnReason } from './types';
import {
  churnReasonsAfterPaywall,
  churnReasonsBeforePaywall,
  needDetailFieldPlaceholderSet,
} from './constants';

interface ChurnReasonDropdownProps {
  where: 'beforePaywall' | 'afterPaywall';
  onSelectReason: (reason: ChurnReason) => void;
}

export const ChurnReasonDropdown: FC<ChurnReasonDropdownProps> = ({ where, onSelectReason }) => {
  const [reason, setReason] = useState<ChurnReason | null>(null);

  const options = where === 'beforePaywall' ? churnReasonsBeforePaywall : churnReasonsAfterPaywall;

  const shouldShowTextInpupt = Object.keys(needDetailFieldPlaceholderSet).includes(
    reason?.mainReason
  );

  const handleMainReasonChange = (event: SelectChangeEvent<string>) => {
    setReason({
      mainReason: event.target.value,
    });
    onSelectReason({
      mainReason: event.target.value,
    });
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReason((prev) => ({ ...prev, detailReason: e.target.value }));
    onSelectReason({
      ...reason,
      detailReason: e.target.value,
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-name-label">Select Reason</InputLabel>
      <Select
        labelId="churn-reason-label"
        value={reason?.mainReason}
        onChange={handleMainReasonChange}
        label="Select Reason"
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {shouldShowTextInpupt && (
        <TextField
          variant="filled"
          placeholder={needDetailFieldPlaceholderSet[reason?.mainReason]}
          onChange={handleTextChange}
        />
      )}
    </FormControl>
  );
};

import { useQuery } from '@tanstack/react-query';
import { RecommendedTask, RecommendedTaskOrigin } from '@demind-inc/core';

import { recommendationsApi } from '../api';

interface UseRecommendedTasksParams {
  userId: string;
  origin?: RecommendedTaskOrigin;
}

interface UseRecommendedTasksQueryParams {
  queryKey: ['recommendedTasks', { userId: string; origin?: RecommendedTaskOrigin }];
}

export const useRecommendedTasks = ({ userId, origin }: UseRecommendedTasksParams) => {
  const recommendedTasksQuery = useQuery({
    queryKey: ['recommendedTasks', { userId, origin }],
    queryFn: ({ queryKey }: UseRecommendedTasksQueryParams) => {
      const [, { userId, origin }] = queryKey;
      return recommendationsApi
        .getRecommendedTasks(userId, origin)
        .then(({ data }) => data as RecommendedTask[]);
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000, // 1 hour
    enabled: !!userId,
  });

  return {
    recommendedTasks: recommendedTasksQuery.data ?? [],
    ...recommendedTasksQuery,
  };
};

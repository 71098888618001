import { Modal } from '@mui/material';
import './CoreActionModal.scss';
import { CloseOutlined } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { selectedCoreActionModalAtom } from '../../../../data-access';

const CoreActionModal = () => {
  const [selectedModal, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const handleClose = () => {
    setSelectedModal(null);
  };
  if (!selectedModal) return null;
  return (
    <Modal open={!!selectedModal} onClose={handleClose} className="core-action-modal">
      <div className="core-action-modal__container">
        <div className="core-action-modal__header">
          <h1 className="core-action-modal__header__title">{selectedModal?.header}</h1>
          <CloseOutlined
            fontSize="medium"
            className="core-action-modal__header__close"
            onClick={handleClose}
          />
        </div>
        {selectedModal.component && <selectedModal.component />}
      </div>
    </Modal>
  );
};

export default CoreActionModal;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';

import { tasksApi } from '../api';
import { taskErrorSnackBarAtom } from '../recoil';

interface UseSyncTodoProjectsParams {
  userId: string;
  todoType: TodoIntegrationType;
}

export function useSyncTodoProjects() {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const syncTodoProjectsMutation = useMutation({
    mutationFn: ({ userId }: UseSyncTodoProjectsParams) => {
      return tasksApi.syncTodoProjects(userId).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
    onError: (error, { todoType }) => {
      if (!!error) {
        // If todo integration failed, need to reconnect so returns 401 error.
        setTaskErrorSnackbar({
          status: 401,
          message: 'Failed to sync.',
          provider: todoType,
        });
      }
    },
  });

  return {
    syncTodoProjects: syncTodoProjectsMutation.mutateAsync,
    ...syncTodoProjectsMutation,
  };
}

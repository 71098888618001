import { FC, useState } from 'react';
import {
  generalSnackBarAtom,
  useAuthContext,
  useDeleteUser,
  useSubscriptionContext,
} from '../../data-access';
import { useRecoilState } from 'recoil';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { canSendForm, ChurnReason, ChurnReasonDropdown, getReasonText } from '../SideBar';

interface DeleteAccountDialogProps {
  visible: boolean;
  where?: 'beforePaywall' | 'afterPaywall';
  onClose: () => void;
}

const formUrl =
  'https://docs.google.com/forms/d/102ic4QCKuFBtiJoUhPbNKjVbWd9Q76PCCidpueXpyWw/formResponse';
enum FormKeys {
  email = 'entry.1156818469',
  reason = 'entry.1642550387',
  userId = 'entry.2042643005',
  platform = 'entry.1845422797',
  status = 'entry.1014463221',
}
const DeleteAccountDialog: FC<DeleteAccountDialogProps> = ({
  visible,
  where = 'beforePaywall',
  onClose,
}) => {
  const { user, signOut } = useAuthContext();
  const { deleteUser: deleteUserApi } = useDeleteUser();
  const [_, setGeneralSnackbar] = useRecoilState(generalSnackBarAtom);
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentSubscription } = useSubscriptionContext();

  const [reason, setReason] = useState<ChurnReason | null>(null);

  const handleSignOut = () => {
    signOut();
    setTimeout(() => {
      window.location.href = window.location.origin;
    }, 1000);
  };

  const handleDeleteUser = async () => {
    if (!user.userId || !canSendForm(reason)) {
      return;
    }
    setIsDeleting(true);
    const submitParams = new FormData();
    submitParams.append(FormKeys.email, user.email);
    submitParams.append(FormKeys.reason, getReasonText(reason, where));
    submitParams.append(FormKeys.userId, user.userId);
    submitParams.append(FormKeys.platform, currentSubscription?.deviceFrom!);
    submitParams.append(FormKeys.status, currentSubscription?.status!);

    try {
      await axios.post(formUrl, submitParams);
    } catch (error) {
      console.error('Error', 'Failed to submit feedback', error);
    } finally {
      await deleteUserApi({ userId: user.userId });
      onClose();
      handleSignOut();
      setGeneralSnackbar('Account deleted successfully');
      setIsDeleting(false);
    }
  };

  return (
    <Dialog open={visible} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>Delete Account</DialogTitle>
      <DialogContent style={{ flexDirection: 'column', display: 'flex', gap: 10 }}>
        <DialogContentText>Are you sure you want to delete your account?</DialogContentText>
        <ChurnReasonDropdown where={where} onSelectReason={setReason} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={handleDeleteUser}
          loading={isDeleting}
          disabled={isDeleting || !canSendForm(reason)}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountDialog;

import { ACTIONS } from './constants';
import './CoreActions.scss';
import { CoreActionModal } from './CoreActionModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selectedCoreActionModalAtom,
  selectedDateAtom,
  visibleFeedbackDialogPostNameAtom,
} from '../../../data-access';
import { Close } from '@mui/icons-material';
import { useEnergyBoost } from '../../../hooks';
import { useOvernightSummary } from '../../../hooks';
import { FC, useMemo } from 'react';
import dayjs from 'dayjs';

const CoreActions: FC = () => {
  const [_, setSelectedModal] = useRecoilState(selectedCoreActionModalAtom);
  const [__, setVisibleFeedbackPostName] = useRecoilState(visibleFeedbackDialogPostNameAtom);
  const { handleClickEnergyBoostBtn, scheduledEnergyboostEvents } = useEnergyBoost();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { hasNoSleepData = false } = useOvernightSummary({ date: selectedDate });

  const availableActions = useMemo(() => {
    let actions = ACTIONS;
    if (hasNoSleepData) {
      actions = actions.filter((action) => !['daily_analysis', 'edit_sleep'].includes(action.id));
    } else {
      actions = actions.filter((action) => action.id !== 'add_sleep');
    }
    if (selectedDate.isAfter(dayjs())) {
      actions = actions.filter((action) => !['edit_sleep', 'add_sleep'].includes(action.id));
    }
    return actions;
  }, [hasNoSleepData, selectedDate]);

  return (
    <div className="core-actions">
      <CoreActionModal />
      {availableActions.map((action) => (
        <div key={action.title} className="core-actions__action-container">
          {!!scheduledEnergyboostEvents.length && action.id === 'energy_boost' && (
            <div className="core-actions__energy-boost-discard" onClick={handleClickEnergyBoostBtn}>
              <Close fontSize="small" /> Discard
            </div>
          )}
          <div
            className="core-actions__action"
            onClick={() => {
              if (action.id === 'ai_scheduler') {
                setVisibleFeedbackPostName(action.id);
                return;
              }

              setSelectedModal(action);
            }}
          >
            <p className="core-actions__action__title">{action.title}</p>
            <p className="core-actions__action__description">{action.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CoreActions;

import { RecommendedTaskOrigin } from '@demind-inc/core';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { RecommendationType } from '../types';
import { useRecommendedTasks } from '../queries';
import { useAuthContext } from '../context';
import { selectedRecommendationTypeAtom } from '../recoil';

export const useRecommendations = () => {
  const { user } = useAuthContext();

  const [selectedRecommendationType, setSelectedRecommendationType] = useRecoilState(
    selectedRecommendationTypeAtom
  );
  const storedTypeInLocal = localStorage.getItem('recommendationType');

  const origin: RecommendedTaskOrigin = useMemo(() => {
    if (selectedRecommendationType === 'chronotype' && user.chronotype) {
      return user.chronotype;
    }
    return 'general';
  }, [selectedRecommendationType, user.chronotype]);

  const { recommendedTasks, isFetching: isFetchingActions } = useRecommendedTasks({
    userId: user.userId,
    origin,
  });

  const { energyBoostActions, actions } = useMemo(() => {
    const energyBoostActions = recommendedTasks.filter(({ type }) => type === 'energyBoost');
    const actions = recommendedTasks.filter(({ type }) => type !== 'energyBoost'); //The custom tasks adding before v1.0.8 might not have type.

    return { energyBoostActions, actions };
  }, [recommendedTasks]);

  useEffect(() => {
    const init = async () => {
      if (storedTypeInLocal === null) {
        setSelectedRecommendationType('default');
      } else {
        setSelectedRecommendationType(storedTypeInLocal as RecommendationType);
      }
    };

    init();
  }, []);

  const handleRecommendationTypeChange = (type: RecommendationType) => {
    setSelectedRecommendationType(type);
    localStorage.setItem('recommendationType',type);
  };

  return {
    isFetchingActions,
    energyBoostActions,
    actions,
    selectedRecommendationType,
    recommendedActionOrigin: origin,
    handleRecommendationTypeChange,
  };
};

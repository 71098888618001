import React, { useState } from 'react';
import { RBCEvent } from '../../CalendarTimeline';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { CalendarAccountDropdown } from '../CalendarAccountDropdown';
import { useCalendarContext } from '../../../../data-access';
import { useEnergyBoost } from '../../../../hooks';
import { ScheduledAction } from '@demind-inc/core';
import './EnergyBoostMenu.scss';
interface EnergyBoostMenuProps {
  visible: boolean;
  onClose: () => void;
}

const EnergyBoostMenu: React.FC<EnergyBoostMenuProps> = ({ visible, onClose }) => {
  const [selectedAction, setSelectedAction] = useState<ScheduledAction | null>(null);
  const { calendarAccountsToCreateEvents } = useCalendarContext();
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>(
    calendarAccountsToCreateEvents[0]?.calendarId
  );
  const {
    selectedEnergyboostOptions,
    onAcceptScheduledEnergyboost,
    onDiscardScheduledEnergyboost,
  } = useEnergyBoost();

  const handleAcceptEnergyboost = () => {
    onAcceptScheduledEnergyboost(selectedAction, selectedCalendarId);
    setSelectedAction(null);
    onClose();
  };

  const handleDiscardEnergyboost = () => {
    onDiscardScheduledEnergyboost();
    setSelectedAction(null);
    onClose();
  };

  return (
    <Dialog
      open={visible}
      className="energy-menu"
      hideBackdrop
      onClose={onClose}
      key={calendarAccountsToCreateEvents[0]?.calendarId}
    >
      <DialogTitle className="energy-menu__header">
        <p>Choose Activity</p>
        <CloseOutlined onClick={onClose} className="energy-menu__header__icon" />
      </DialogTitle>
      <DialogContent className="energy-menu__content">
        <div className="energy-menu__title-container">
          <CalendarAccountDropdown
            calendars={calendarAccountsToCreateEvents}
            onSelectCalendarId={(id) => setSelectedCalendarId(id)}
            selectedCalendarId={selectedCalendarId}
          />
        </div>
        <div className="energy-menu__chip-container">
          {selectedEnergyboostOptions?.options?.map((option) => (
            <Chip
              label={option.name}
              key={option.taskId}
              variant={selectedAction?.taskId === option.taskId ? 'filled' : 'outlined'}
              color="primary"
              onClick={() => setSelectedAction(option)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions className="energy-menu__actions">
        <div className="energy-menu__button-group">
          <Button
            onClick={handleDiscardEnergyboost}
            variant="text"
            className="energy-menu__button-group__button delete-button"
          >
            Discard
          </Button>

          <Button
            variant="contained"
            className="energy-menu__button-group__button"
            onClick={handleAcceptEnergyboost}
            disabled={!selectedAction || !selectedCalendarId}
          >
            Accept as calendar event
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EnergyBoostMenu;

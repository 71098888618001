import React, { useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';

import './CreateEventMenu.scss';
import {
  circadianPhaseInfo,
  selectedDateAtom,
  useCalendarContext,
  useCircadianContext,
  useCreateEventMenuContext,
} from '../../../../data-access';
import { EventTimeSelector } from '../EventTimeSelector';
import { EventDescription } from '../EventDescription';
import { EventCategories } from '../EventCategories';
import { EventCategorySet } from '@demind-inc/core';
import { CalendarAccountDropdown } from '../CalendarAccountDropdown';
import { formatTaskTimeRange } from '../../../../helpers';
import { useGeneralSettings } from '../../../../hooks/useGeneralSettings';

interface CreateEventMenuProps {
  visible: boolean;
  onClose: () => void;
}

const CreateEventMenu: React.FC<CreateEventMenuProps> = ({ visible, onClose }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(selectedDate);
  const [endDate, setEndDate] = useState<Dayjs>(selectedDate.add(30, 'minutes'));
  const [description, setDescription] = useState('');
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<EventCategorySet[]>([]);
  const { creatingEvent, handleCreateEvent, clearCreatingEvent } = useCreateEventMenuContext();
  const { mainCalendar, calendarAccountsToCreateEvents } = useCalendarContext();
  const { findPhaseForTaskTime } = useCircadianContext();
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();

  const targetPhase = findPhaseForTaskTime(
    startDate.format('YYYY-MM-DD HH:mm'),
    endDate.format('YYYY-MM-DD HH:mm')
  );
  const phase = circadianPhaseInfo[targetPhase];

  const taskTime = formatTaskTimeRange({
    startDatetime: startDate,
    endDatetime: endDate,
    timeFormat,
  });

  // Set default values
  useEffect(() => {
    if (!creatingEvent) {
      setTitle('');
      setStartDate(selectedDate);
      setEndDate(selectedDate.add(30, 'minutes'));
      setDescription('');
      setSelectedCategories([]);
      if (!mainCalendar || selectedCalendarId) {
        return;
      }
      setSelectedCalendarId(mainCalendar.calendarId || '');
      return;
    }

    setStartDate(creatingEvent.start?.date ? dayjs(creatingEvent.start.date) : selectedDate);
    setEndDate(
      creatingEvent.end?.date ? dayjs(creatingEvent.end.date) : selectedDate.add(30, 'minutes')
    );
  }, [creatingEvent, mainCalendar]);

  const canSave = useMemo(
    () => !!title.trim() && startDate.isBefore(endDate),
    [title, startDate, endDate]
  );
  const handleSaveEvent = () => {
    handleCreateEvent({
      summary: title,
      start: { date: startDate.toISOString(), timeZone: dayjs.tz.guess() },
      end: { date: endDate.toISOString(), timeZone: dayjs.tz.guess() },
      description,
      categories: selectedCategories,
      calendarId: selectedCalendarId,
    });
    clearCreatingEvent();
    onClose();
  };

  const handleKeyDown = (key: string) => {
    if (!canSave) {
      return;
    }
    if (key === 'Enter') {
      handleSaveEvent();
    }
  };

  return (
    <Dialog
      open={visible}
      className="create-event-menu"
      hideBackdrop
      onClose={onClose}
      disableRestoreFocus
      onKeyDown={({ key }) => handleKeyDown(key)}
    >
      <DialogTitle className="create-event-menu__header">
        <p>Event</p>
        <CloseOutlined onClick={onClose} className="create-event-menu__header__icon" />
      </DialogTitle>
      <DialogContent className="create-event-menu__content">
        <div className="create-event-menu__title-container">
          <TextField
            autoFocus
            value={title}
            placeholder="Add title"
            onChange={(e) => setTitle(e.target.value)}
            variant="standard"
            className="create-event-menu__title"
          />
          <EventDescription
            description={description}
            onChangeDescription={setDescription}
            className="create-event-menu__content__item"
          />
        </div>
        {taskTime && phase && (
          <div className="create-event-menu__phase-container">
            {taskTime && (
              <div
                className="create-event-menu__phase-container__time"
                style={{ borderColor: phase?.color, color: phase?.color }}
              >
                {taskTime}
              </div>
            )}
            {phase?.label && (
              <div
                className="create-event-menu__phase-container__phase"
                style={{ backgroundColor: phase.color }}
              >
                {phase.label}
              </div>
            )}
          </div>
        )}
        <EventTimeSelector
          startDateTime={startDate}
          endDateTime={endDate}
          onStartTimeChange={(val) => setStartDate(val)}
          onEndTimeChange={(val) => setEndDate(val)}
        />
        <EventCategories
          className="create-event-menu__content__item"
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <CalendarAccountDropdown
          calendars={calendarAccountsToCreateEvents}
          onSelectCalendarId={(id) => setSelectedCalendarId(id)}
          selectedCalendarId={selectedCalendarId}
        />
      </DialogContent>
      <DialogActions className="create-event-menu__actions">
        <Button
          variant="contained"
          className="create-event-menu__actions__button"
          disabled={!canSave}
          onClick={handleSaveEvent}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventMenu;

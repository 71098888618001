import React, { FC } from 'react';
import { MenuBar } from './MenuBar';
import './DataView.scss';
import { useRecoilValue } from 'recoil';
import { selectedDataMenuItem } from '../../data-access';
import { DataViewMenuItems } from './constants';

const DataView: FC = () => {
  const selectedDataMenu = useRecoilValue(selectedDataMenuItem);
  const DataViewComponent = DataViewMenuItems.find(
    (item) => item.id === selectedDataMenu
  ).component;

  return (
    <div className="data-view">
      <MenuBar className="data-view__menu-bar" />
      <DataViewComponent className="data-view__dashboard" />
    </div>
  );
};

export default DataView;

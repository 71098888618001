import { DropdownItem } from './types';

export const churnReasonsBeforePaywall: DropdownItem[] = [
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Didn’t think it was a paid app', value: 'didnt_think_paid' },
  { label: 'Technical issues/bugs', value: 'technical_issues' },
  { label: 'Security concerns', value: 'security_concerns' },
  { label: 'Just exploring', value: 'just_exploring' },
  { label: 'Other', value: 'other' },
];

export const churnReasonsAfterPaywall: DropdownItem[] = [
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Didn’t understand how to use the app', value: 'didnt_understand' },
  { label: 'Technical issues/bugs', value: 'technical_issues' },
  { label: 'Security concerns', value: 'security_concerns' },
  { label: 'Not what I need/expected', value: 'not_what_i_need' },
  { label: 'Lack of features/content', value: 'lack_of_features' },
  { label: 'Other', value: 'other' },
];

export const needDetailFieldPlaceholderSet: Record<string, string> = {
  too_expensive: 'How much would you pay for',
  technical_issues: 'Describe the technical issues you faced',
  lack_of_features: 'What features would you like to see?',
  not_what_i_need: 'What were you looking for?',
  other: 'Please specify',
};

import { Chronotype } from '@demind-inc/core';
import {
  DailyAnalysisModalView,
  EditSleepModalView,
  EnergyBoostModalView,
} from './CoreActionModal/ActionModalViews';
import { CoreActonModalItem } from '../../../data-access';

export const ACTIONS: CoreActonModalItem[] = [
  {
    id: 'ai_scheduler',
    title: 'AI Scheduler',
    description: 'Schedule tasks instantly',
    header: '',
  },
  {
    id: 'energy_boost',
    title: 'Energy Boost',
    description: 'Energize your day',
    component: EnergyBoostModalView,
    header: 'Energy-Boost',
  },
  {
    id: 'daily_analysis',
    title: 'Daily Analysis',
    description: "Check today's energy",
    component: DailyAnalysisModalView,
    header: 'Analysis of Your Day',
  },
  {
    id: 'edit_sleep',
    title: 'Edit Sleep',
    description: 'Edit your sleep data',
    component: EditSleepModalView,
    header: 'Edit Sleep',
  },
  {
    id: 'add_sleep',
    title: 'Add Sleep',
    description: 'Add your sleep data',
    component: EditSleepModalView,
    header: 'Add Sleep',
  },
];
export const chronotypeTitleSet: Record<Chronotype, string> = {
  morning: 'Morning Type',
  hybrid: 'Hybrid Type',
  night: 'Night Type',
};

export const BASIC_SLEEP_TIME = 0;
export const BASIC_SLEEP_TIME_12H = 12;
export const BASIC_AWAKE_TIME = 8;

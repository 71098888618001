import {
  Calendar,
  CalendarEvent,
  CalendlyIntegration,
  Device,
  EMADateSet,
  EnergyFluctuationItem,
  FluctuateReasonType,
  HeatmapDataType,
  PhaseStartEndSet,
  TaskItem,
  TodoIntegrationType,
  TodoTasksBoard,
  Token,
  User,
} from '@demind-inc/core';
import { SettingsActionItem } from '../components';

export enum Status {
  LOADING = 'loading',
  UN_AUTHORIZED = 'unAuthorized',
  AUTHORIZED = 'authorized',
}

export type FirestoreUser = Omit<User, 'userId'>;
export type FirestoreTaskItem = Omit<TaskItem, 'taskId'>;
export type FirestoreCalendar = Omit<Calendar, 'calendarId'>;
export type FirestoreToken = Omit<Token, 'tokenId'>;
export type FirestoreCalendlyInteg = Omit<CalendlyIntegration, 'calendlyIntegrationId'>;
export type FirestoreEMADateSet = Omit<EMADateSet, 'emaDateId'>;
export type FirestoreDevice = Omit<Device, 'deviceId'>;

export type PeakDipPhase =
  | 'wakeup_low'
  | 'morning_high'
  | 'morning_low'
  | 'evening_high'
  | 'evening_low';

export type PeakDipStartEndSet = Pick<PhaseStartEndSet, PeakDipPhase>;

export interface CircadianPhaseBoundariesByDate {
  date: string;
  details: PhaseStartEndSet;
}

export interface PeaksDipsBoundariesByDate {
  date: string;
  details: PeakDipStartEndSet;
}

export type CalendarEventCreateOption = Partial<Omit<CalendarEvent, 'updatedDate' | 'createdDate'>>;
export type CalendarEventModifyOption = Partial<
  Omit<CalendarEvent, 'eventId' | 'updatedDate' | 'createdDate' | 'calendarId'>
>;

export type FluctuationSetByReasons = Partial<Record<FluctuateReasonType, EnergyFluctuationItem[]>>;

export interface TasksByDate {
  date: string;
  tasks: TaskItem[];
}

export interface CircadianRhythmByDate {
  date: string;
  values: HeatmapDataType[];
  fluctuations?: FluctuationSetByReasons;
}

export type EventDetailsMenuMode = 'details' | 'edit' | 'energy_boost';

export type PaymentProvider = 'stripe' | 'revenuecat';

export type AddTodoTaskItemProps = Omit<TaskItem, 'taskId' | 'originalTaskId'>;
export type AddTodoTaskBoardProps = Omit<
  TodoTasksBoard,
  'todoTaskId' | 'originalBoardId' | 'createdAt'
>;

export interface TaskErrorInfo {
  message: string;
  status: number;
  provider?: TodoIntegrationType;
  action?: 'sync' | 'add' | 'delete' | 'update';
}

export interface WearableErrorInfo {
  message: string;
  status: number;
}

export interface SettingsModalInfo {
  visible: boolean;
  defaultPosition?: SettingsActionItem;
}
export interface PeakDipStartEndSetByDate {
  date: string;
  details: PeakDipStartEndSet;
}
export interface PeakEnergyByDate {
  date: string;
  peakEnergy?: number;
}

export type DataTrendPeriod = 'weekly' | 'daily';

export type RecommendationType = 'default' | 'chronotype';

export type CoreActionOption =
  | 'ai_scheduler'
  | 'energy_boost'
  | 'daily_analysis'
  | 'edit_sleep'
  | 'add_sleep';

export interface CoreActonModalItem {
  id: CoreActionOption;
  title: string;
  description: string;
  component?: React.ComponentType;
  header: string;
}

export interface SleepWakeTimeISOStringSet {
  sleepTime: string; // YYYY-MM-DDTHH:mm:ss
  wakeTime: string; // YYYY-MM-DDTHH:mm:ss
}
export type ViewSwitcherStateType = 'Home' | 'Data';

export type DataMenuItem = 'dataCircadian' | 'dataTask' | 'dataSleep' | 'dataTrend';

import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Add, ArrowDropDown } from '@mui/icons-material';

import './CTAMenu.scss';
import { CTAMenuType } from './types';

interface CTAMenuProps {
  onClick: (cta: CTAMenuType) => void;
}

const CTAMenu: React.FC<CTAMenuProps> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickCTA = (cta: CTAMenuType) => {
    onClick(cta);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="cta-menu__button"
      >
        <Add fontSize={'small'} />
        Create
        <ArrowDropDown />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        className="cta-menu__menu"
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem value="event" onClick={() => handleClickCTA('event')}>
          Event
        </MenuItem>
        <MenuItem value="task" onClick={() => handleClickCTA('task')}>
          Task
        </MenuItem>
      </Menu>
    </>
  );
};

export default CTAMenu;

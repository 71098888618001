import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import './SkipConnectDeviceDialog.scss';

interface SkipConnectDeviceDialogProps {
  visible: boolean;
  onClose: () => void;
  onSkip: () => void;
}

const SkipConnectDeviceDialog: React.FC<SkipConnectDeviceDialogProps> = ({
  visible,
  onClose,
  onSkip,
}) => {
  return (
    <Dialog open={visible} onClose={onClose} className="skip-connect-device-dialog">
      <DialogTitle>Are you sure to skip?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Skipping device connection may lead to less accurate energy tracking. <br />
          You can connect devices later from the settings page. If you use Apple Health, Google Fit,
          or Samsung Health, connect them through the mobile app’s settings.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSkip} color="primary">
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkipConnectDeviceDialog;

import React from 'react';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { LinkOff, Sync } from '@mui/icons-material';

import './TodoListMoreDropdown.scss';
import { TodoListMoreDropdownOption } from './types';

interface TodoListMoreDropdownProps {
  anchorEl: HTMLElement | null;
  sourceTitle: string;
  loadingOption?: TodoListMoreDropdownOption;
  onClickItem: (option: TodoListMoreDropdownOption) => void;
  onClose: () => void;
}

const options: Record<TodoListMoreDropdownOption, { actionName: string; icon: JSX.Element }> = {
  sync: {
    actionName: 'Sync',
    icon: <Sync fontSize="small" />,
  },
  disconnect: {
    actionName: 'Disconnect',
    icon: <LinkOff fontSize="small" />,
  },
};

const TodoListMoreDropdown: React.FC<TodoListMoreDropdownProps> = ({
  anchorEl,
  sourceTitle,
  loadingOption,
  onClickItem,
  onClose,
}) => {
  return (
    <Menu
      className="todo-list-more-dropdown"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {Object.entries(options).map(([option, { actionName, icon }]) => (
        <MenuItem
          key={option}
          disabled={!!loadingOption}
          onClick={() => onClickItem(option as TodoListMoreDropdownOption)}
          className="todo-list-more-dropdown__option"
        >
          {loadingOption === option ? <CircularProgress size={15} /> : icon}
          <p className="todo-list-more-dropdown__option__title">
            {actionName} {sourceTitle}
          </p>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TodoListMoreDropdown;
